import { useState } from "react";
import { Button, Select, MenuItem } from "@mui/material";
import palette from "src_common/theme/palette";
import { SupportedFileTypes } from "src_common/components/yao-form-fields";
import AddIcon from "@mui/icons-material/Add";
import {
  IFileList,
  ListCorrespondenceFilesModal,
} from "./ListCorrespondencesFilesModal.component";

interface IFile {
  readonly file: File;
  readonly filename: string;
}

interface IProps {
  matterId: string;
  attachments: IFile[];
  setAttachments: Function;
  onChoose?: (val: any) => void;
  paymentNotification?: {
    files: Array<IFileList>;
  };
}

const menuItens = {
  from_local_files: {
    key: "from_local_files",
    text: "Upload local files",
  },
  from_correspondences: {
    key: "from_correspondences",
    text: "Upload from correspondences",
  },
};

export function UploadButton({
  matterId,
  attachments,
  setAttachments,
  onChoose,
  paymentNotification,
}: IProps) {
  const [menuSelected, setMenuSelected] = useState<string>(
    menuItens.from_correspondences.key
  );
  const [
    showListCorrespondenceFilesModal,
    setShowListCorrespondenceFilesModal,
  ] = useState(false);
  const [showLocalFiles, setShowLocalFiles] = useState(false);

  const handleSelectChange = (eventTargetValue: string) => {
    setShowListCorrespondenceFilesModal(false);
    setShowLocalFiles(false);
    setMenuSelected(eventTargetValue);
    if (eventTargetValue === menuItens.from_local_files.key) {
      setShowLocalFiles(true);
    }
    if (eventTargetValue === menuItens.from_correspondences.key) {
      setShowListCorrespondenceFilesModal(true);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        component="label"
        onClick={(e: any) => {
          setShowListCorrespondenceFilesModal(false);
          setShowLocalFiles(false);
          if (e.target.value !== 0) {
            if (menuSelected === menuItens.from_local_files.key) {
              setShowLocalFiles(true);
            }
            if (menuSelected === menuItens.from_correspondences.key) {
              setShowListCorrespondenceFilesModal(true);
            }
          }
        }}
      >
        {showLocalFiles && (
          <input
            hidden
            accept={Object.values(SupportedFileTypes)
              .map((ft) => `.${ft}`)
              .join(",")}
            onChange={async (e) => {
              const files = Array.from(e.target.files || []);
              if (!files) return;
              const iFiles: IFile[] = files.map((file) => {
                return { file, filename: file.name };
              });
              setAttachments([...attachments, ...iFiles]);
            }}
            multiple
            type="file"
          />
        )}
        {menuItens[menuSelected as keyof typeof menuItens].text}
        <Select
          value={origin}
          onChange={(e) => handleSelectChange(e.target.value)}
          sx={{
            padding: 0,
            border: 0,
            "&& .MuiOutlinedInput-notchedOutline": {
              border: 0,
            },
            "&& .MuiSelect-select": {
              paddingTop: 0,
              paddingBottom: 0,
            },
            "&& .MuiSvgIcon-root": {
              color: "white",
            },
            "&& .MuiSelect-icon": {
              right: -6,
            },
          }}
          IconComponent={AddIcon}
          MenuProps={{
            sx: {
              "&& .Mui-selected": {
                backgroundColor: "white",
              },
            },
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          }}
        >
          <MenuItem
            value="from_local_files"
            sx={{
              color: palette.yao.primary[3],
              opacity: origin === "from_local_files" ? 0.7 : 1,
              fontSize: 12,
              fontWeight: 400,
            }}
          >
            Upload local files
          </MenuItem>
          <MenuItem
            value="from_correspondences"
            sx={{
              color: palette.yao.primary[3],
              opacity: origin === "from_correspondences" ? 0.7 : 1,
              fontSize: 12,
              fontWeight: 400,
            }}
          >
            Upload from correspondences
          </MenuItem>
        </Select>
      </Button>
      {showListCorrespondenceFilesModal && (
        <ListCorrespondenceFilesModal
          matterId={matterId}
          attachments={attachments}
          setAttachments={setAttachments}
          handleClose={setShowListCorrespondenceFilesModal}
          visible={showListCorrespondenceFilesModal}
          paymentNotification={paymentNotification}
          onChoose={onChoose}
        />
      )}
    </>
  );
}
