import {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import customAxios from "src_common/utils/axios";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import { useSnackbar } from "notistack";
import { Correspondence } from "src_lawfirm/api/correspondence";
import palette from "src_common/theme/palette";
import CustomIcon from "src_common/components/custom-icon";
import { FileViewer } from "src_common/components/FileViewer";
import { DialogAnimate } from "src_common/components/animate";
import {
  SupportedFileTypes,
  useDownloadFileFromS3,
} from "src_common/components/yao-form-fields";
import NiceModal from "@ebay/nice-modal-react";
import YaoCorrespondenceGptDialog from "src_lawfirm/pages/yao-gpt/yao-correspondence-gpt";
import { FORMAT_WORD } from "src_common/utils/getFileFormat";
import { getCorrespondenceIcon } from "../CorrespondenceFolderTab/helpers";
import CorrespondenceFileEditComponent from "./CorrespondenceFileEdit.component";
import { WopiContext } from "src_common/contexts/WopiContext";

interface P {
  correspondence: Correspondence | null;
  noBorder?: boolean;
  handleClose: (val: Correspondence | null) => void;
}

export const CorrespondenceFileViewer = ({
  correspondence,
  handleClose,
}: P): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = useState<string | null>(null);
  const [pdfViewModal, setPdfViewModal] = useState(false);
  const [isOfficeDocument, setIsOfficeDocument] = useState<boolean>(false);
  const { directDownload } = useDownloadFileFromS3();
  const formRef = useRef<HTMLFormElement>();
  const wopiContext = useContext(WopiContext);

  const getFilePreassigned = async (correspondenceId: string) => {
    try {
      const { data } = await customAxios.get<string>(
        `/correspondence/${correspondenceId}`
      );
      if (!data) throw new Error("Invalid response from server");
      setFile(data);
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };

  useEffect(() => {
    setFile(null);
    // @ts-ignore
    setIsOfficeDocument(
      [
        SupportedFileTypes.DOC,
        SupportedFileTypes.DOCX,
        SupportedFileTypes.XLS,
        SupportedFileTypes.XLXS,
      ].indexOf((correspondence?.key || "").split(".").pop()) !== -1
    );
    if (correspondence) {
      getFilePreassigned(correspondence._id);
    }
  }, [correspondence]); // eslint-disable-line react-hooks/exhaustive-deps

  const ext = useMemo(
    () => correspondence?.key?.split(".").pop() || "",
    [correspondence]
  );

  const handleOpenFile = async () => {
    const key = correspondence?.key || "";
    const token = correspondence?.wopi_token || "";
    if (!key.length && !token.length) {
      return;
    }
    if (SupportedFileTypes.PDF === ext || !token.length) {
      enqueueSnackbar(
        "The file will be opened in another tab in a few seconds",
        {
          variant: "info",
        }
      );
      await directDownload(key);
      return;
    }
    const url = wopiContext.generateWopiUrl(ext, key, token, true, false);
    if (url) {
      window.open(url, "_blank");
    } else {
      enqueueSnackbar("There was an error, please try again later.", {
        variant: "error",
      });
    }
  };

  const handleEditFile = () => {
    if (!correspondence) {
      return;
    }
    NiceModal.show(CorrespondenceFileEditComponent, {
      correspondence,
      wopiContext,
    });
    handleClose && handleClose(null);
  };

  return (
    <Box width="100%" height="100%">
      <Box
        display="flex"
        gap="15px"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" gap="16px">
          {correspondence && getCorrespondenceIcon(correspondence)}
          <Typography
            display="block"
            fontSize="18px"
            fontWeight="600"
            sx={{ wordWrap: "break-word" }}
          >
            {correspondence?.title || ""}
          </Typography>
        </Box>

        <Box display="flex">
          {isOfficeDocument ? (
            <IconButton onClick={handleEditFile} sx={{ mr: 1 }}>
              <CustomIcon name="pencil" />
            </IconButton>
          ) : null}
          {file && (
            <>
              <IconButton onClick={handleOpenFile} sx={{ mr: 1 }}>
                <CustomIcon name="open_url" />
              </IconButton>
              <IconButton
                onClick={() => saveAs(file || "", correspondence?.title)}
                sx={{ mr: 3 }}
              >
                <CustomIcon name="download" />
              </IconButton>
            </>
          )}
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        gap="2px"
        alignItems="start"
        mt="30px"
      >
        <Typography fontSize="11px" color={palette.yao.grey[7]}>
          Time & Date
        </Typography>
        <Typography fontSize="13px">
          {correspondence?.created_at
            ? format(new Date(correspondence.created_at), "hh:mm a, d.MM.yy")
            : ""}
        </Typography>
      </Box>
      {/* {FORMAT_WORD.includes(ext) ? (<Stack direction="column" spacing={2} alignItems="start" mt="30px">
                <Button variant="outlined" fullWidth onClick={() => {
                    // @ts-ignore
                    NiceModal.show(YaoCorrespondenceGptDialog, {
                        correspondence
                    })
                }}>Explain this document to my client</Button>
            </Stack>) : null} */}

      <Box
        className="pdf-viewer-doc pdf-viewer-doc-2"
        mt="10px"
        sx={{ height: "100%" }}
      >
        <Typography fontSize="11px" color={palette.yao.grey[7]} pb="4px">
          PREVIEW
        </Typography>
        <div
          className="pdf-viewer-doc"
          style={{ height: "100%", paddingLeft: "10px" }}
        >
          {file && ext && (
            <>
              <form
                ref={formRef}
                id="office_form"
                name="office_form"
                target="office_frame"
                action=""
                method="post"
              ></form>
              <FileViewer
                file={file}
                ext={ext}
                wopi_token={correspondence?.wopi_token || ""}
                wopi_key={correspondence?.key || ""}
                wopi_form={formRef}
              />
            </>
          )}
        </div>
      </Box>

      <DialogAnimate
        maxWidth="sm"
        open={pdfViewModal}
        onClose={() => setPdfViewModal(false)}
        className="cs_corresponding-modal"
      >
        {file && ext && (
          <div className="pdf-viewer-doc">
            <FileViewer file={file} ext={ext} />
          </div>
        )}
      </DialogAnimate>
    </Box>
  );
};
