import React, {MouseEvent, SyntheticEvent, useEffect, useMemo, useState} from "react";
import {UseFormSetValue} from "react-hook-form";
import {useParams} from "react-router";
import {useInfiniteQuery, useQueryClient} from "@tanstack/react-query";

import {Autocomplete, CircularProgress, Grid, TextField, Tooltip} from "@mui/material";
import {LibraryAdd} from "@mui/icons-material";
import _ from "lodash";

import {YaoFormFieldLabel} from "src_common/components/yao-form/YaoForm";
import {YAOFieldAutocomplete, YAOFieldAutocompleteOption,} from "src_common/components/yao-form-fields";
import {createNarrative, getNarrative, Narrative, NarrativeQueryRequest, NarrativeType} from "../../api/narratives";
import {useAPI} from "../../../src_common/hooks/useAPI";
import {getMatter} from "../../api/matters";
import {useSnackbar} from "notistack";

type Props = {
  accounts: any[];
  type?: string;
  isTo: boolean;
  handleAcct: Function;
  setValue?: UseFormSetValue<any>;
  hideLabelTo?: boolean;
  isM2M?: boolean;
};

export default function InternalAccount({
  accounts,
  type,
  isTo,
  handleAcct,
  setValue,
  hideLabelTo = false,
  isM2M = false,
}: Props) {
  const [options, setOptions] = useState<YAOFieldAutocompleteOption[]>([]);
  const [inputValue, setInputValue] = useState<string>("");

  const { matterId } = useParams();
  const matterAPI = useAPI(getMatter);
  const { enqueueSnackbar } = useSnackbar();

  const [filters, setFilters] = useState<NarrativeQueryRequest>({
    type: NarrativeType.PAYMENT,
    department: undefined,
    search: "",
    limit: 20,
    page: 0,
  });

  const queryClient = useQueryClient();

  const refetchQuery = async () => {
    await queryClient.invalidateQueries(["narratives", filters, departmentId])
  };

  useEffect(() => {
    if (matterId) {
      // @ts-ignore
      matterAPI.invoke(matterId)
    }

    const nextOptions = Object.values(accounts || [])
      .filter((e: any) => e.type === type)
      .map((account: any) => {
        const value = JSON.stringify({
          account_id: account._id,
          account_number: account.account_number,
          account_name: account.name
        });
        return {
          label: _.capitalize(
            `${account.account_number} - ${account.name} (${account.type})`
          ),
          value,
          changeVal: value,
        };
      });
    setOptions(nextOptions);
    if (Array.isArray(nextOptions) && nextOptions.length > 0) {
      setValue &&
        setValue(isTo ? "to.account" : "from.account", nextOptions[0]);
      handleAcct && handleAcct(nextOptions[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts, matterId]);

  const departmentId = matterAPI?.data?.department?._id

  const narrativesQuery = useInfiniteQuery(
      ["narratives", filters, departmentId],
      ({ pageParam }) =>getNarrative({
          ...filters,
          department: departmentId,
          page: pageParam,
        }),
      {
        enabled: !!departmentId,
        getNextPageParam: (lastPage, allPages) =>
            Array.isArray(lastPage.rows) && !lastPage.rows.length? undefined : Number(lastPage.page || 0) + 1,
      },
  );

  const narrativeRows = useMemo(() => {
    return (narrativesQuery.data?.pages || [])
        .flatMap((page) => page.rows || [])
        .map((narrative) => ({
          ...narrative,
        }))
        .map((narrativeItem: Narrative) => {
          const value = JSON.stringify({
            narrative_id: narrativeItem._id,
            narrative_text: narrativeItem.text,
            narrative_type: narrativeItem.type
          });
          return {
            label: _.capitalize(narrativeItem.text),
            value,
            changeVal: value,
          };
        })
        .reduce((acc, curr) => {
          if (!acc.some((item: any) => item.label === curr.label)) {
            acc.push(curr);
          }
          return acc;
        }, [] as YAOFieldAutocompleteOption[]);
  }, [narrativesQuery.data]);

  const updateFilter = (key: keyof NarrativeQueryRequest, value: any) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
    refetchQuery();
  };

  const handleSearchFilter = (searchValue: string) =>
      updateFilter("search", searchValue.trim());

  const addNarrative = async () => {

    const isAddedNarrative = (items: {label: string}[]) => items
        .map(item => item?.label)
        .some((item: string) => item.toLocaleLowerCase() === inputValue.trim().toLocaleLowerCase())

    if (!inputValue?.length) return
    // @ts-ignore
    if(isAddedNarrative([...narrativeRows])) return

    await createNarrative({
      type: NarrativeType.PAYMENT,
      text: inputValue,
      department: departmentId,
    })
    enqueueSnackbar(`Narrative ${inputValue} created successfully`);
    refetchQuery()
  }

  const forceLabbelToHide = ((isTo && hideLabelTo) || (isM2M && hideLabelTo));

  return (
    <Grid container spacing={3}>
      <Grid item xs={forceLabbelToHide ? 12 : 6}>
        <YaoFormFieldLabel
          name={isTo ? "to.payment_label" : "from.payment_label"}
          label={"Make a payment " + (isTo ? "to" : "from")}
          required
        />
        <YAOFieldAutocomplete
          name={isTo ? "to.account" : "from.account"}
          placeholder="Select an option"
          options={options}
          customOnChange={(v: string) => {
            handleAcct(v || "");
          }}
        />
      </Grid>
      {forceLabbelToHide ? null : (
        <Grid item xs={6}>
          <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
          >
            <Grid item>
            <YaoFormFieldLabel
              name={isTo ? "to.reference_label" : "from.reference_label"}
              label="Display on Ledger as:"
              required={true}
            />
            </Grid>
            <Grid item>
              <Tooltip title="Remember for future use" >
                <LibraryAdd color="info" fontSize="medium" onClick={addNarrative}/>
              </Tooltip>
            </Grid>
          </Grid>

          <Autocomplete
              id={isTo ? "to.reference" : "from.reference"}
              loading={narrativesQuery.isFetchingNextPage}
              options={narrativeRows}
              disableClearable
              freeSolo
              handleHomeEndKeys
              selectOnFocus
              renderInput={(params) => (
                  <TextField
                      {...params}
                      fullWidth
                      placeholder="enter text to be displayed on ledger"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                              {narrativesQuery.isFetchingNextPage ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                        ),
                      }}
                  />
              )}
              inputValue={inputValue}
              onInputChange={(e: SyntheticEvent, value: string) => {
                e.preventDefault();
                e.stopPropagation();
                setInputValue(value);
                setValue && setValue(isTo ? "to.reference" : "from.reference", value);

                handleSearchFilter(value);
                refetchQuery()
              }}
              ListboxProps={{
                // @ts-ignore
                onScroll: (e: SyntheticEvent, value: any) => {
                  const listboxNode = e.currentTarget;
                  if (
                      listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 10
                      && narrativesQuery.hasNextPage && !narrativesQuery.isFetchingNextPage
                  ) {
                    narrativesQuery.fetchNextPage();
                  }
                }
              }}
              onChange={(e: SyntheticEvent, newValue: any) => {
                e.preventDefault();
                e.stopPropagation();
                let name = "";
                if (typeof newValue === "string") {
                  name = newValue;
                } else if (newValue && newValue.inputValue) {
                  name = newValue.inputValue;
                } else {
                  name = newValue.title ?? newValue.label;
                }
                setValue && setValue(isTo ? "to.reference" : "from.reference", name);
              }}
            />
        </Grid>
      )}
    </Grid>
  );
}
