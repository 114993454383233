import type { AuthUser } from "src_common/@types/auth";
import CustomIcon from "src_common/components/custom-icon";
import { AttorneyPermission } from "src_lawfirm/api/attorneys";
import {
  PATH_ACCOUNTS,
  PATH_HOME,
  PATH_MATTERS,
  PATH_SUPPORT,
  PATH_TASKS,
  PATH_CONTACTS,
  PATH_FORMS,
  PATH_PRECEDENTS,
  PATH_USER_MGT,
  PATH_EXIT,
  PATH_WORKFLOW,
  PATH_POST_HANDLING, PATH_ROOT_CONFIGURATION,
} from "./paths";
import { PropsMenuContext } from "src_common/components/menu/menu-context";
import { ProviderContext } from "notistack";

function getIcon(name: string) {
  return <CustomIcon name={name} sx={{ p: 0.1 }} />;
}

const navConfig = [
  {
    subheader: "",
    items: [
      {
        title: "Home",
        path: PATH_HOME.root,
        icon: getIcon("dashboard"),
      },
      {
        title: "Tasks",
        path: PATH_TASKS.root,
        icon: getIcon("task"),
      },
      {
        title: "Matters",
        path: PATH_MATTERS.root,
        icon: getIcon("matter"),
      },
      {
        title: "Post Office",
        path: PATH_POST_HANDLING.root,
        icon: getIcon("post_office"),
        getBadge: (ctx: PropsMenuContext) => ctx.postOffice || 0,
      },
      {
        title: "Contacts",
        path: PATH_CONTACTS.root,
        icon: getIcon("contact"),
      },
      {
        title: "Accounting",
        path: PATH_ACCOUNTS.root,
        icon: getIcon("bank"),
        visible: (user: AuthUser) =>
          !!user &&
          Array.isArray(user.permissions) &&
          (user.permissions.includes(AttorneyPermission.ADMIN) ||
            user.permissions.includes(AttorneyPermission.ACCOUNTANT)),
      },
      {
        title: "Reports",
        path: "",
        icon: getIcon("report"),
      },
      {
        title: "Forms",
        path: PATH_FORMS.root,
        icon: getIcon("form"),
        visible: (user: AuthUser) =>
          !!user &&
          Array.isArray(user.permissions) &&
          user.permissions.filter((p) => p !== AttorneyPermission.USER).length >
            0,
      },
      {
        title: "Templates",
        path: PATH_PRECEDENTS.root,
        icon: getIcon("precedent"),
        visible: (user: AuthUser) =>
          !!user &&
          Array.isArray(user.permissions) &&
          user.permissions.filter((p) => p !== AttorneyPermission.USER).length >
            0,
      },
      {
        title: "Workflow",
        path: PATH_WORKFLOW.root,
        icon: getIcon("workflow"),
        visible: (user: AuthUser) =>
          !!user &&
          Array.isArray(user.permissions) &&
          (user.permissions.includes(AttorneyPermission.ADMIN) ||
            user.permissions.includes(AttorneyPermission.WORKFLOW_MANAGER)),
      },
      {
        title: "Configuration",
        path: PATH_ROOT_CONFIGURATION.root,
        icon: getIcon("configuration"),
      },
    ],
  },
  {
    subheader: "",
    bottom: true,
    items: [
      {
        title: "User Management ",
        path: PATH_USER_MGT.root,
        icon: getIcon("user"),
        visible: (user: AuthUser) =>
          !!user &&
          Array.isArray(user.permissions) &&
          user.permissions.includes(AttorneyPermission.ADMIN),
      },
      {
        title: "Help & Support",
        path: PATH_SUPPORT.root,
        icon: getIcon("support"),
        visible: (user: AuthUser, context: PropsMenuContext) =>
          context.tawkToRef?.current,
        onClick: (context: PropsMenuContext, snackbar: ProviderContext) => {
          const tawkTo = context.tawkToRef?.current || undefined;
          if (!tawkTo) {
            return;
          }
          if (tawkTo.isChatHidden()) {
            switch (tawkTo.getStatus()) {
              case "online":
                tawkTo.showWidget();
                tawkTo.maximize();
                break;
              default:
                snackbar.enqueueSnackbar(
                  "Sorry, live support is not available right now",
                  { variant: "info" }
                );
                break;
            }
          } else {
            tawkTo.minimize();
            tawkTo.hideWidget();
          }
        },
      },
      {
        title: "Suggest a Feature",
        icon: getIcon("suggestion"),
        path: "https://yao.upvoty.com",
      },
      {
        title: "Exit",
        path: PATH_EXIT.root,
        icon: getIcon("exit"),
      },
    ],
  },
];

export default navConfig;
