import { ChangeEvent, useEffect, useState } from "react";
import NiceModal from "@ebay/nice-modal-react";
import { Button, FormControl, Stack, TextField } from "@mui/material";
import {
  createFormTemplate,
  deleteFormTemplate,
  getFormTemplates,
  updateFormTemplate,
} from "src_lawfirm/api/forms";
import { FormTemplate, FormType } from "src_common/@interfaces/forms";
import HeaderBreadcrumbs from "src_common/components/HeaderBreadcrumbs";
import Iconify from "src_common/components/Iconify";
import { useAPI } from "src_common/hooks/useAPI";
import YaoTable from "src_common/components/yao-table";
import PrecedentTemplateDialog from "src_common/components/precedents/PrecedentTemplateDialog";
import { fDate } from "src_common/utils/formatTime";
import ConfirmDialog from "src_common/components/dialog/ConfirmDialog";
import useDebounce from "src_common/hooks/useDebounce";
import { Box } from "@mui/material";

const Letterheads: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const getFormsAPI = useAPI<FormTemplate[]>(getFormTemplates);
  const { debounce } = useDebounce();

  useEffect(() => {
    getFormsAPI.invoke({
      search: searchTerm?.length > 0 ? searchTerm : undefined,
      form_type: FormType.LETTERHEAD,
    });
  }, []);

  useEffect(() => {
    debounce(() => {
      getFormsAPI.invoke({
        search: searchTerm?.length > 0 ? searchTerm : undefined,
        form_type: FormType.LETTERHEAD,
      });
    }, 400);
  }, [searchTerm]);

  const handleEditForm = (template: FormTemplate) => {
    NiceModal.show(PrecedentTemplateDialog, {
      saveFn: updateFormTemplate,
      formType: FormType.LETTERHEAD,
      template,
      inLawFirm: true,
    }).then((response) => {
      if (!!response) {
        getFormsAPI.invoke({
          form_type: FormType.LETTERHEAD,
        });
      }
    });
  };

  const handleRemoveForm = (template: FormTemplate) => {
    NiceModal.show(ConfirmDialog, {
      title: "Are you sure you want to delete this letterhead template?",
      description: "This item will be deleted, you can't undo it!",
    }).then(async () => {
      await deleteFormTemplate(template._id, {
        is_deleted: true,
      });
      getFormsAPI.invoke({
        form_type: FormType.LETTERHEAD,
      });
    });
  };

  const handleAddLetterhead = () => {
    NiceModal.show(PrecedentTemplateDialog, {
      saveFn: createFormTemplate,
      formType: FormType.LETTERHEAD,
      inLawFirm: true,
    }).then((response) => {
      if (!!response) {
        getFormsAPI.invoke({
          form_type: FormType.LETTERHEAD,
        });
      }
    });
  };

  return (
    <>
      <HeaderBreadcrumbs
        heading=""
        action={
          <Stack direction="row" spacing={3} alignItems="center">
            <FormControl>
              <TextField
                id="text-search"
                label="Search by title"
                variant="outlined"
                name="text-search"
                value={searchTerm}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setSearchTerm(e.target.value)
                }
                InputProps={{
                  sx: { p: 0, width: 250 },
                }}
              />
            </FormControl>
            <Button
              className="yaoer-new-button"
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={handleAddLetterhead}
            >
              Add Letterhead
            </Button>
          </Stack>
        }
      />
      <YaoTable
        rows={getFormsAPI.data || []}
        isLoading={getFormsAPI.loading}
        columns={[
          { id: "title", label: "Name", sx: { width: "100%" } },
          {
            id: "created_at",
            label: "Created",
            formatter: fDate,
            sx: { width: "3rem" },
          },
          {
            id: "",
            label: "",
            formatter: () => <Box sx={{ width: "0.8rem" }}></Box>,
          },
        ]}
        rowOptions={[
          {
            action: handleEditForm,
            label: "Edit",
            icon: "eva:edit-fill",
          },
          {
            action: handleRemoveForm,
            label: "Remove",
            icon: "eva:trash-2-outline",
          },
        ]}
        labelNoData={"No letterheads at this point"}
        imgNoData={"/assets/illustrations/illustration_empty_content.svg"}
      />
    </>
  );
};

export default Letterheads;
