import {
  createRef,
  FC,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  CheatSheet,
  CheatSheetField,
  CheatSheetTab,
} from "./CheatSheet.interface";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { copyToClipboard } from "src_common/utils/clipboard";
import { useSnackbar } from "notistack";
import Iconify from "src_common/components/Iconify";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useAPI } from "../../hooks/useAPI";
import { FormTemplate, FormType } from "../../@interfaces/forms";
import { getFormTemplates } from "../../../src_lawfirm/api/forms";
import useDebounce from "../../hooks/useDebounce";

const RenderFields: FC<{ fields: CheatSheetField[] }> = ({ fields = [] }) => {
  const { enqueueSnackbar } = useSnackbar();
  const inputRef = createRef<HTMLInputElement>();

  if (!fields.length) {
    return null;
  }

  const handleCopy = (value: string) => {
    copyToClipboard(value, inputRef, (err) => {
      if (err) {
        enqueueSnackbar("Try again", { variant: "error" });
        return;
      }
      enqueueSnackbar("Field key copied to clipboard", { variant: "success" });
    });
  };

  return (
    <>
      {fields.map(({ label, key = "", sub = "", children = [] }, index) => {
        const hasKey = key?.length > 0;
        const hasChildren = Array.isArray(children) && children.length > 0;
        const hasSub = sub?.length > 0;
        const borderBottomWidth = fields.length - 1 === index ? 0 : 1;

        return (
          <Box
            key={index}
            sx={{
              borderBottomColor: "#EBF2F3",
              borderBottomStyle: "solid",
              borderBottomWidth,
            }}
          >
            <Stack direction="row" spacing={1}>
              <Typography
                flexGrow={1}
                fontSize="14px"
                lineHeight="32px"
                fontWeight={400}
                sx={{ color: "#0D394D" }}
              >
                {label}{" "}
                {hasSub && (
                  <small
                    style={{
                      fontSize: "11px",
                      lineHeight: "32px",
                      fontWeight: "400",
                    }}
                  >
                    {sub}
                  </small>
                )}
              </Typography>
              {hasKey && (
                <>
                  <Typography
                    fontSize="13px"
                    lineHeight="32px"
                    fontWeight={400}
                    sx={{ color: "#646D76" }}
                  >
                    {key}
                  </Typography>
                  <IconButton size="small" onClick={() => handleCopy(key)}>
                    <Iconify icon="charm:copy" color="#0186B0" />
                  </IconButton>
                </>
              )}
            </Stack>
            {hasChildren &&
              children.map((child, idx) => {
                return (
                  <Stack direction="row" spacing={1} key={idx}>
                    <Typography
                      flexGrow={1}
                      fontSize="14px"
                      lineHeight="32px"
                      fontWeight={600}
                      sx={{ color: "#0D394D", ml: 1 }}
                    >
                      {child.label}{" "}
                      {typeof child.sub === "string" && child.sub?.length > 0 && (
                        <small
                          style={{
                            fontSize: "11px",
                            lineHeight: "32px",
                            fontWeight: "400",
                          }}
                        >
                          {child.sub}
                        </small>
                      )}
                    </Typography>
                    {typeof child.key === "string" && child.key?.length > 0 && (
                      <>
                        <Typography
                          fontSize="13px"
                          lineHeight="32px"
                          fontWeight={400}
                          sx={{ color: "#646D76" }}
                        >
                          {child.key}
                        </Typography>
                        <IconButton
                          size="small"
                          onClick={() => handleCopy(child.key as string)}
                        >
                          <Iconify icon="charm:copy" color="#0186B0" />
                        </IconButton>
                      </>
                    )}
                  </Stack>
                );
              })}
          </Box>
        );
      })}
    </>
  );
};

const RenderTabs: FC<{ tabs: CheatSheetTab[] }> = ({ tabs = [] }) => {
  const [tab, setTab] = useState<string>("0");

  if (!tabs.length) {
    return null;
  }

  return (
    <TabContext value={tab}>
      <TabList
        onChange={(event: SyntheticEvent, value: string) => setTab(value)}
        sx={{
          "& .MuiTabs-indicator": {
            display: "none",
          },
          "& .MuiTabs-flexContainer": {
            flexWrap: "wrap",
          },
          "& MuiTab-root": {
            fontWeight: 500,
            fontSize: "15px",
            lineHeight: "24px",
            color: "#919EAB",
            borderRadius: "0!important",
          },
          "& .Mui-selected": {
            borderBottom: "2px solid #0186B0",
            color: "#0D394D",
          },
        }}
      >
        {tabs.map((tab, index) => {
          const active = Number(tab) === index;

          return (
            <Tab
              label={tab.label}
              key={index}
              value={index.toString()}
              sx={{ borderRadius: 0 }}
            />
          );
        })}
      </TabList>
      {tabs.map((tab, index) => (
        <TabPanel key={index} value={index.toString()}>
          <RenderFields fields={tab.fields} />
        </TabPanel>
      ))}
    </TabContext>
  );
};

const RenderFormSearch: FC<{ ignore?: string }> = ({ ignore = undefined }) => {
  const { debounce, reset: clearDebounce } = useDebounce();
  const [open, setOpen] = useState<boolean>(false);
  const getFormsAPI = useAPI<FormTemplate[]>(getFormTemplates);
  const [current, setCurrent] = useState<FormTemplate | undefined>(undefined);
  const [inputValue, setInputValue] = useState<string>("");

  useEffect(() => {
    getFormsAPI.invoke(query);
  }, []);

  useEffect(() => {
    clearDebounce();
    debounce(async () => {
      getFormsAPI.invoke(query);
    }, 250);
  }, [inputValue]);

  const query = useMemo(
    () => ({
      search: inputValue.length > 0 ? inputValue : undefined,
      form_type: FormType.FORM,
    }),
    [inputValue]
  );

  const handleClick = (
    _: SyntheticEvent,
    value: FormTemplate | undefined | null
  ) => {
    setCurrent(value || undefined);
  };

  return (
    <Box mb={3}>
      <Typography
        fontSize="18px"
        lineHeight="24px"
        fontWeight={600}
        mb={1}
        sx={{ color: "#0D394D" }}
      >
        Link data from Form
      </Typography>
      <Autocomplete
        id="link-data"
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(o: FormTemplate, v: FormTemplate) =>
          o._id === v._id
        }
        options={(getFormsAPI.data || []).filter((d) => d._id !== ignore)}
        loading={getFormsAPI.loading}
        value={current}
        blurOnSelect={true}
        onChange={handleClick}
        inputValue={inputValue}
        onInputChange={(_: SyntheticEvent, value: string) =>
          setInputValue(value)
        }
        noOptionsText={
          inputValue.length > 0 ? "Form not found" : "Type anything to search"
        }
        disableClearable
        forcePopupIcon={false}
        clearOnEscape={true}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search form by name"
            fullWidth
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password1989",
            }}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              endAdornment: <Iconify icon="eva:search-fill" />,
            }}
          />
        )}
        getOptionLabel={(o) => o.title}
        sx={{ mb: 2 }}
      />
      {!Array.isArray(current?.fields) ||
      !(current?.fields || []).length ? null : (
        <>
          <RenderFields
            fields={[
              {
                label: "Form fields",
                children: (current?.fields || []).map((f) => ({
                  label: f.name,
                  key: `{forms.${current?.label}.${f.label}}`,
                })),
              },
            ]}
          />
        </>
      )}
    </Box>
  );
};

const CheatSheetModal = NiceModal.create<CheatSheet>(
  ({
    title = "",
    description = [],
    groups = [],
    currentForm = undefined,
    enableFormsSearch = false,
  }) => {
    const modal = useModal();

    return (
      <Dialog
        maxWidth="md"
        fullWidth
        open={modal.visible}
        scroll="paper"
        onClose={() => modal.hide()}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
      >
        <DialogContent sx={{ padding: "16px" }}>
          <Stack direction="column" justifyItems="flex-start" mb={2}>
            <Box>
              <IconButton onClick={() => modal.hide()}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography
              width="100%"
              textAlign="center"
              fontSize="21px"
              lineHeight="24px"
              fontWeight={600}
              mb={2}
              sx={{ color: "#0D394D" }}
            >
              {title}
            </Typography>
            {description.map((des, i) => (
              <Typography
                key={i}
                width="80%"
                marginLeft="10%"
                textAlign="center"
                fontSize="14px"
                lineHeight="22px"
                fontWeight={400}
                sx={{ color: "#646D76" }}
              >
                {des}
              </Typography>
            ))}
          </Stack>
          {groups.map((group, gidx) => (
            <Box key={gidx} mb={3}>
              <Typography
                fontSize="18px"
                lineHeight="24px"
                fontWeight={600}
                mb={1}
                sx={{ color: "#0D394D" }}
              >
                {group.title}
              </Typography>
              {Array.isArray(group.fields) && group.fields.length > 0 && (
                <RenderFields fields={group.fields} />
              )}
              {Array.isArray(group.tabs) && group.tabs.length > 0 && (
                <RenderTabs tabs={group.tabs} />
              )}
            </Box>
          ))}
          {enableFormsSearch ? <RenderFormSearch ignore={currentForm} /> : null}
        </DialogContent>
      </Dialog>
    );
  }
);

export default CheatSheetModal;
