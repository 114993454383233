import { YAOFieldAutocompleteOptionObject } from "src_common/components/yao-form-fields";

export enum FormType {
  FORM = "FORM",
  PRECEDENT = "PRECEDENT",
  LETTERHEAD = "LETTERHEAD",
}

export enum FormFieldType {
  TEXT = "TEXT",
  PARAGRAPH = "PARAGRAPH",
  NUMBER = "NUMBER",
  YES_NO = "YES_NO",
  DATE = "DATE",
}

export const FormFieldTypeOptions = [
  { value: FormFieldType.TEXT, label: "Text" },
  { value: FormFieldType.PARAGRAPH, label: "Paragraph" },
  { value: FormFieldType.NUMBER, label: "Number" },
  { value: FormFieldType.YES_NO, label: "Yes/No" },
  { value: FormFieldType.DATE, label: "Date" },
];

export interface FormField {
  label: string;
  name: string;
  type: FormFieldType;
  answer?: string;
}

export interface FormCaseType {
  case_type: string;
  case_type_id?: string;
  department: string;
  department_id?: string;
}

export interface FormTemplate {
  _id: string;
  form_type: FormType;
  title: string;
  label: string;
  description: string;
  letterhead?: string;
  fields?: FormField[];
  case_types: FormCaseType[];
  law_firm?: string;
  is_deleted: boolean;
  created_at: string;
  updatedAt: string;
  document_template_path: string;
}

export interface FormTemplateDto {
  form_type: FormType;
  title: string;
  letterhead?: string;
  description: string;
  fields?: Pick<FormField, "name" | "type">[];
  case_types: string[];
  document_template_path: string | null;
}

export interface FormTemplateDuplicateDto {
  title: string;
}

export interface FormTemplateQueryDto {
  form_type: FormType;
  department_id?: string | undefined;
  backoffice_forms?: boolean | undefined;
  search?: string | undefined;
}

export interface FormTemplateDeleteDto {
  is_deleted: boolean;
}

export interface FormTemplateModel {
  title: string;
  description: string;
  fields: {
    _id: string;
    name: string;
    label: string;
    type: { value: string; label: string };
  }[];
  case_types: YAOFieldAutocompleteOptionObject[];
  letterhead?: YAOFieldAutocompleteOptionObject;
  document_template_path: string | null;
}

export interface Form {
  _id: string;
  matter: string;
  template: {
    _id: string;
    title: string;
    document_template_path: string;
  };
  title: string;
  description: string;
  author: string;
  law_firm: string;
  created_at: Date;
  updated_at: Date;
  fields: FormField[];
  filled_document_path?: string;
  filled_at?: Date;
  filled_by?: string;
  filled_id?: string;
  contact?: string;
}

export interface FormAnswer {
  label: string;
  answer: string;
}

export interface FormAnswersDto {
  matter: string;
  form: string;
  answers: FormAnswer[];
}
