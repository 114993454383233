import NiceModal from "@ebay/nice-modal-react";
import Page from "src_common/components/Page";
import { isEqual } from "date-fns";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Checkbox,
  Container,
  Drawer,
  FormControl,
  Grid,
  Link,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  IconButton,
  Menu,
  ListSubheader,
  TableRow,
  Stack,
} from "@mui/material";
import YaoTable from "src_common/components/yao-table";
import { AvatarCell } from "src_common/components/yao-table/custom-cells";
import palette from "src_common/theme/palette";
import CustomIcon from "src_common/components/custom-icon";
import React, { useEffect, useMemo, useState } from "react";
import { AddNewTask } from "./TaskForms/new-task";
import { useNavigate, useParams } from "react-router";
import {
  getTasks,
  Task,
  TaskCategory,
  TaskCategoryToText,
  TaskFilter,
  TaskFormList,
  TaskPriority,
  TaskStatus,
  TaskStatusForm,
  TaskUpdateForm,
  updateTask,
  updateTaskStatus,
} from "src_lawfirm/api/tasks";
import { useAPI } from "src_common/hooks/useAPI";
import { TaskFilters } from "./TaskForms";
import { PATH_TASKS } from "src_lawfirm/routes/paths";
import { UpdateTask } from "./TaskForms/update-task";
import { TaskCommentsList } from "./TaskCommentForms";
import useAuth from "src_common/hooks/useAuth";
import Dialog from "src_common/components/dialog/Dialog";
import { TaskFilterForm } from "./TaskForms/task-filters";
import { useSnackbar } from "notistack";
import { format } from "date-fns";
import { Attorney, getAttorneys } from "src_lawfirm/api/attorneys";
import { DatePicker } from "@mui/lab";
import useDebounce from "src_common/hooks/useDebounce";
import { getMatter } from "src_lawfirm/api/matters";
import { startOfDay } from "date-fns/esm";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { Contact, ContactType } from "src_lawfirm/api/contacts";
import { CellProps } from "src_common/components/yao-table/types";
import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useInfiniteScrollQuery } from "../../../src_common/hooks/useInfiniteScrollQuery";
import Iconify from "src_common/components/Iconify";
interface P {
  setUpdatingTask: (row: Task) => void;
  setCommentPanel: (row: Task) => void;
  updateStatus: (form: TaskStatusForm) => void;
  taskUpdate: (form: TaskUpdateForm, id: string) => void;
  updateDueDate: (id: string, date?: Date | null) => void;
  debounce: (fn: any, delay?: number | undefined) => void;
  matterMembers?: Attorney[];
  otherMembers?: Attorney[];
  matterId?: string;
  anchorEl: null | HTMLElement;
  statusOpen: boolean;
  handleClose: () => void;
  handleClick: (event: React.MouseEvent<HTMLElement>, row?: Task) => any;
  currentTaskSelected?: Task;
  pathname: string;
}

export const priorityColor: { [key: string]: string } = {
  LOW: palette.yao.secondary[1],
  URGENT: palette.yao.secondary[2],
  CRITICAL_DATE: palette.yao.secondary[4],
  STANDARD: palette.yao.secondary[6],
  TO_DO: palette.yao.secondary[14],
  IN_PROGRESS: palette.yao.secondary[4],
  COMPLETED: palette.yao.primary[3],
  low: palette.yao.secondary[12],
  urgent: palette.yao.secondary[10],
  critical_date: palette.yao.secondary[13],
  standard: palette.yao.secondary[11],
};

export const priorityLabel: { [key: string]: string } = {
  LOW: "LOW",
  URGENT: "URGENT",
  CRITICAL_DATE: "CRITICAL DATE",
  STANDARD: "STANDARD",
  TO_DO: "TO-DO",
  IN_PROGRESS: "IN PROGRESS",
  COMPLETED: "COMPLETED",
  low: "LOW",
  urgent: "URGENT",
  critical_date: "CRITICAL DATE",
  standard: "STANDARD",
};
export const statusLabel: { [key: string]: string } = {
  TO_DO: "TO-DO",
  IN_PROGRESS: "IN PROGRESS",
  COMPLETED: "COMPLETED",
};

const getColumns = ({
  setUpdatingTask,
  setCommentPanel,
  updateStatus,
  taskUpdate,
  updateDueDate,
  debounce,
  matterMembers,
  otherMembers,
  matterId,
  anchorEl,
  statusOpen,
  handleClose,
  handleClick,
  currentTaskSelected,
  pathname,
}: P) => [
  {
    id: "status",
    label: "Status",
    sx: { width: pathname !== PATH_TASKS.root ? "8.2rem" : "6.8rem" },
    formatter: (_: string, row: Task) => {
      return (
        <Select
          id="status"
          label="Status"
          defaultValue={row?.status}
          size="small"
          className="priority-list"
          sx={{
            border: "1px solid",
            color: priorityColor[row?.status],
            borderColor: palette.yao.grey[5],
            fontSize: "9px",
            fontWeight: 500,
            height: "22px",
            paddingLeft: 0,
            paddingRight: 0,
            marginRight: 1,
            "& .MuiSvgIcon-root": {
              color: priorityColor[row?.status],
              fontSize: "12px",
            },
          }}
          onChange={(val) =>
            updateStatus({
              status: {
                label: val.target.value,
                value: val.target.value,
              },
              id: row._id,
            })
          }
        >
          {(Object.values(TaskStatus) || []).map((status) => (
            <MenuItem key={status} value={status}>
              <Typography
                component="span"
                color={priorityColor[status]}
                sx={{ fontSize: "9px !important" }}
              >
                {statusLabel[status]}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      );
    },
  },
  {
    id: "priority",
    label: "Priority",
    sx: { width: "8rem" },
    formatter: (_: string, row: Task) => {
      return (
        <Select
          id="priority"
          label="Priority"
          defaultValue={row?.priority}
          size="small"
          className="priority-list"
          sx={{
            padding: 0,
            color: priorityColor[row?.priority],
            background: priorityColor[row?.priority.toLowerCase()],
            height: "22px",
            "& .MuiSvgIcon-root": {
              color: priorityColor[row?.priority],
              fontSize: "12px",
            },
          }}
          onChange={(val) =>
            taskUpdate({ priority: val.target.value }, row._id)
          }
        >
          {(Object.values(TaskPriority) || []).map((priority) => (
            <MenuItem key={priority} value={priority}>
              <Typography
                component="span"
                color={priorityColor[priority]}
                sx={{ fontSize: "9px", fontWeight: "500", lineHeight: "20px" }}
              >
                {priorityLabel[priority]}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      );
    },
  },
  {
    id: "due_date",
    label: "Due",
    sx: { width: "5.35rem" },
    formatter: (_: string, row: Task) => {
      return (
        <DatePicker
          value={row.due_date || null}
          onChange={(val) => updateDueDate(row._id, val)}
          InputProps={{
            style: { height: "40px", paddingLeft: 0 },
            disableUnderline: true,
          }}
          OpenPickerButtonProps={{ sx: { ml: -2, mt: -0.25 }, size: "small" }}
          inputFormat="dd.MM.yy"
          renderInput={(params) => (
            <TextField
              sx={{
                svg: {
                  color: palette.yao.grey[7],
                  height: "14px",
                  width: "14px",
                },
              }}
              variant="standard"
              {...params}
            />
          )}
          disableMaskedInput
          components={{
            OpenPickerIcon: CalendarTodayOutlinedIcon,
          }}
        />
      );
    },
  },
  {
    id: "title",
    label: "Task heading",
    sx: { width: "100%" },
    formatter: (_: string, row: Task) => {
      return (
        <Tooltip
          title={
            row.is_trigger_task
              ? "By Completing this task, will trigger next section tasks in the task tab"
              : ""
          }
        >
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            <Stack alignItems="center" direction="row" gap={0.5}>
              <Link
                onClick={() => setUpdatingTask(row)}
                sx={{ margin: 0 }}
                title={row.title || ""}
              >
                {row.title || "-"}
              </Link>
              {row.is_trigger_task && (
                <Iconify
                  icon="f7:hand-point-right"
                  style={{
                    color: palette.yao.primary[1],
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginLeft: "8px",
                  }}
                />
              )}
            </Stack>
          </div>
        </Tooltip>
      );
    },
  },
  {
    id: "category",
    label: "Action",
    sx: { width: "48px" },
    formatter: (_: string, row: Task) => {
      const ITEM_HEIGHT = 52;
      return (
        <div>
          <Box title={TaskCategoryToText(row?.category as TaskCategory)}>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={statusOpen ? "long-menu" : undefined}
              aria-expanded={statusOpen ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              disabled={(row.matter_section || "").trim().length > 0}
            >
              <CustomIcon name="arrow" size={12} />
            </IconButton>
          </Box>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={statusOpen}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: "20ch",
                boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.01)",
              },
            }}
          >
            {(Object.values(TaskCategory) || []).map((category) => (
              <MenuItem
                key={row._id + category}
                selected={category === currentTaskSelected?.category}
                onClick={(event) => handleClick(event, currentTaskSelected)}
                value={category}
                disabled={
                  [
                    TaskCategory.WORKFLOW_TRIGGER,
                    TaskCategory.PRECEDENT,
                    TaskCategory.FORM,
                  ].indexOf(category) !== -1
                }
                sx={{
                  fontSize: "12px",
                  color: palette.yao.primary[3],
                  textTransform: "capitalize",
                }}
              >
                {TaskCategoryToText(category)}
              </MenuItem>
            ))}
          </Menu>
        </div>
      );
    },
  },
  {
    id: "task_comments",
    label: "Comments",
    sx: { width: "4.375rem" },
    formatter: (_: string, row: Task) => {
      return (
        <Tooltip
          componentsProps={{
            tooltip: { sx: { backgroundColor: "white" } },
          }}
          title={
            <Grid style={{ width: "286px", height: "100%", padding: "20px" }}>
              {row?.last_comment ? (
                <>
                  <Typography
                    sx={{
                      color: "#919EAB",
                      fontSize: "11px",
                      paddingBottom: "6px",
                      textTransform: "uppercase",
                    }}
                  >
                    Last Comment
                  </Typography>

                  <Typography
                    sx={{
                      background: "#EBEFF8",
                      color: "#212B36",
                      padding: "10px 14px 10px 10px",
                      borderRadius: "4px",
                      fontSize: "13px",
                    }}
                  >
                    {row?.last_comment?.comment}
                  </Typography>

                  <Typography
                    sx={{
                      color: palette.yao.primary[3],
                      fontSize: "11px",
                      paddingTop: "6px",
                    }}
                  >
                    {row?.comment_author?.name}
                    {", "}
                    {row?.last_comment?.created_at &&
                      format(
                        new Date(row?.last_comment?.created_at),
                        "d.MMM.yy"
                      )}
                  </Typography>
                </>
              ) : (
                <Typography sx={{ color: "#212B36", fontSize: "13px" }}>
                  No comment
                </Typography>
              )}
            </Grid>
          }
        >
          <Button
            sx={{ padding: 0, width: "26px", height: "36px" }}
            className="todo-button"
            onClick={() => setCommentPanel(row)}
            startIcon={<CustomIcon name="chat" />}
            onTouchStart={() => row.last_comment}
          >
            <Badge
              badgeContent={row?.task_comments || 0}
              className="select-list"
              componentsProps={{
                root: {
                  style: {
                    color: palette.yao.secondary[2],
                    fontSize: "10px",
                    position: "relative",
                    bottom: "8px",
                    right: "10px",
                  },
                },
              }}
            />
          </Button>
        </Tooltip>
      );
    },
  },
  {
    id: "assigned_to",
    label: pathname !== PATH_TASKS.root ? "Assigned to" : "",
    sx: { width: pathname !== PATH_TASKS.root ? "10.4rem" : 0 },
    formatter: (_: string, row: Task) => {
      return (
        <Box
          sx={{
            display: pathname !== PATH_TASKS.root ? "flex" : "none",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{
              mr: 1,
              height: 22,
              width: 22,
              borderRadius: "3px",
              fontWeight: 500,
              fontSize: 11,
            }}
            alt={row.assigned_to.name}
          >
            {row.assigned_to.name.charAt(0).toUpperCase()}
          </Avatar>

          {(matterMembers || []).length > 0 ? (
            <Select
              id="assigned_to"
              label="Assigned to"
              defaultValue={row?.assigned_to._id}
              size="small"
              sx={{ padding: 0 }}
              className="priority-list todo-list action-list"
              onChange={(val) => {
                taskUpdate({ assigned_to: val.target.value }, row._id);
              }}
            >
              <ListSubheader>Responsible Persons</ListSubheader>
              {matterMembers?.map((option: Attorney) => (
                <MenuItem
                  key={option._id}
                  value={option._id}
                  sx={{ fontSize: "12px" }}
                >
                  <Typography
                    maxWidth={"7rem"}
                    noWrap={true}
                    title={`${option.name} ${option.surname}`}
                  >
                    {option.name} {option.surname || ""}
                  </Typography>
                </MenuItem>
              ))}
              <ListSubheader>Others</ListSubheader>
              {(otherMembers || []).map((option: Attorney) => (
                <MenuItem key={option._id} value={option._id}>
                  <Typography
                    maxWidth={"7rem"}
                    noWrap={true}
                    title={`${option.name} ${option.surname}`}
                  >
                    {option.name} {option.surname || ""}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Select
              id="assigned_to"
              label="Assigned to"
              defaultValue={row?.assigned_to._id}
              size="small"
              sx={{ padding: 0 }}
              className="priority-list todo-list action-list"
              onChange={(val) => {
                taskUpdate({ assigned_to: val.target.value }, row._id);
              }}
            >
              <ListSubheader>Others</ListSubheader>
              {(otherMembers || []).map((option: Attorney) => (
                <MenuItem key={option._id} value={option._id}>
                  <Typography
                    maxWidth={"7rem"}
                    noWrap={true}
                    title={`${option.name} ${option.surname}`}
                  >
                    {option.name} {option.surname || ""}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          )}
        </Box>
      );
    },
  },
  {
    id: "estimate_time",
    label: "Time",
    sx: { width: "5.375rem" },
    formatter: (_: string, row: Task) => {
      return (
        <TextField
          name="estimate_time"
          type="number"
          size="small"
          className="priority-list estimate-time"
          defaultValue={row.estimate_time || undefined}
          placeholder="0"
          onChange={(val) =>
            debounce(
              () =>
                taskUpdate(
                  { estimate_time: parseInt(val.target.value, 10) },
                  row._id
                ),
              1000
            )
          }
          InputProps={{
            endAdornment: <Typography>min</Typography>,
            sx: { padding: 0 },
          }}
        />
      );
    },
  },
  {
    id: "author",
    label: "Author",
    sx: { width: "6.25rem" },
    CellComponent: AvatarCell,
  },
  {
    id: "",
    label: "",
    sx: { width: "2.5rem" },
    formatter: () => {
      return <div style={{ marginLeft: "0.9rem" }} />;
    },
  },
];

export const defaultFilters: TaskFilter = {
  status: [TaskStatus.TO_DO, TaskStatus.IN_PROGRESS],
  category: [],
  priority: [],
  matter: [],
  search: "",
  page: 1,
  limit: 20,
};

export const defaultTask: TaskFormList = {
  title: "",
  priority: TaskPriority.LOW,
  status: TaskStatus.TO_DO,
  category: TaskCategory.FORM,
  description: "",
  assigned_to: "",
  notify_flag: false,
  estimate_time: 0,
  matter: "",
};

interface Props {
  showMatter?: boolean;
}

export function ClientNameCell({ data, formatter }: CellProps) {
  const clientData = data as Array<Contact>;

  const clients = clientData
    .map((c) => c.display_name)
    .join(", ")
    .trim();
  if (!clients.length) {
    return null;
  }

  return (
    <Typography
      display="flex"
      alignItems="center"
      noWrap={true}
      title={clients}
    >
      <Typography noWrap={true}>{clients}</Typography>
    </Typography>
  );
}

export default function TasksTab({ showMatter }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { debounce } = useDebounce();
  const searchDebounce = useDebounce();

  const { user } = useAuth();
  const { pathname } = useLocation();
  const [filters, setFilters] = useState<TaskFilterForm>({
    ...defaultFilters,
    assigned_to: user
      ? {
          label: user.name,
          value: user._id,
        }
      : undefined,
    author: undefined,
    due_date: showMatter ? new Date() : undefined,
  });
  const [searchParams] = useSearchParams();
  const open = searchParams.get("open");

  const [updatingTask, setUpdatingTask] = useState<Task | null>();
  const [commentPanel, setCommentPanel] = useState<Task | null>();
  const [addForm, setAddForm] = useState(false);
  const [myTasksFilter, setMyTasksFilter] = useState(showMatter ? true : false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const statusOpen = Boolean(anchorEl);
  const [currentTaskSelected, setCurrentTaskSelected] = useState<
    Task | undefined
  >();
  const [inputValue, setInputValue] = useState<string>("");

  const navigate = useNavigate();
  const { matterId } = useParams();
  const attorneysAPI = useAPI(getAttorneys);
  const matterAPI = useAPI(getMatter);

  const queryClient = useQueryClient();

  const tasksQuery = useInfiniteQuery(
    ["tasks", filters, matterId, myTasksFilter],
    ({ pageParam }) =>
      getTasks({
        ...filters,
        assigned_to: myTasksFilter ? filters?.assigned_to?.value : "all",
        author: filters?.author?.value,
        matter: matterId ? [matterId] : [],
        page: pageParam,
        limit: 20,
      }),
    {
      getNextPageParam: (lastPage, allPages) =>
        Array.isArray(lastPage.rows) && !lastPage.rows.length
          ? undefined
          : allPages.length + 1,
      onSuccess: (data) => {
        if (typeof open === "string" && open.length > 0) {
          const row = (data?.pages || [])
            .flatMap((page) => page.rows || [])
            .find(({ _id }: Task) => _id === open);
          if (row) {
            setUpdatingTask(row);
          }
        }
      },
    }
  );
  const tasksInfiniteQuery = useInfiniteScrollQuery({
    fetch:
      !tasksQuery.isFetching && tasksQuery.hasNextPage
        ? tasksQuery.fetchNextPage
        : undefined,
  });

  const rows = useMemo(() => {
    return (tasksQuery.data?.pages || []).flatMap((page) => page.rows || []);
  }, [tasksQuery.data]);

  useEffect(() => {
    if (typeof open !== "string" || !open.length) {
      return;
    }
    const updatedFilters = {
      ...filters,
      status: [],
      assigned_to: { label: "All", value: "all" },
    };
    setMyTasksFilter(false);
    setFilters(updatedFilters);
  }, [open]);

  useEffect(() => {
    searchDebounce.reset();
    searchDebounce.debounce(() => {
      setFilters((old) => ({ ...old, search: inputValue }));
    }, 250);
  }, [inputValue]);

  const refetchQuery = () => {
    queryClient.invalidateQueries(["tasks", filters, matterId, myTasksFilter]);
  };

  const taskUpdate = async (form: TaskUpdateForm, id: string) => {
    try {
      const updatedTask = await updateTask(form, id);

      enqueueSnackbar(`${updatedTask.title} task updated`);
      refetchQuery();
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const updateDueDate = async (id: string, date?: Date | null) => {
    try {
      const task = rows.find((task) => task._id === id);
      if (!task || !date) return;

      if (!task.due_date) {
        return taskUpdate({ due_date: date }, id);
      }

      const previousDueDate = startOfDay(new Date(task.due_date));
      const newDueDate = startOfDay(new Date(date));

      if (isEqual(previousDueDate, newDueDate)) return;

      return taskUpdate({ due_date: date }, id);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const updateStatus = async (form: TaskStatusForm) => {
    try {
      const updatedTask = await updateTaskStatus({
        status: form.status,
        id: form.id,
      });
      enqueueSnackbar(`Status updated for ${updatedTask.title}`);
      refetchQuery();
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  useEffect(() => {
    attorneysAPI.invoke();
    if (matterId) matterAPI.invoke(matterId);
  }, [commentPanel, myTasksFilter, filters]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleStatusFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const clickedOption = e.target.value;

    const status = e.target.checked
      ? [...filters.status, clickedOption]
      : filters.status.filter((option) => option !== clickedOption);

    const updatedFilters = { ...filters, status };

    setFilters(updatedFilters);
  };

  const handleTaskFilter = (checked: boolean) => {
    checked
      ? setFilters({
          ...filters,
          assigned_to: {
            label: user && user.name,
            value: user && user._id,
          },
        })
      : setFilters({
          ...filters,
          assigned_to: {
            label: "All",
            value: "all",
          },
        });
    setMyTasksFilter(checked);
  };

  const handleRemoveTask = async (task: Task) => {
    try {
      const updatedTask = await updateTaskStatus({
        status: { value: "DELETED" },
        id: task._id,
      });
      enqueueSnackbar(`Task ${updatedTask.title} removed`);
      refetchQuery();
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const matterColumns = [
    {
      id: "clients",
      label: "Client",
      CellComponent: ClientNameCell,
      sx: { width: "150px" },
    },
    {
      id: "matter",
      label: "Matter",
      sx: { width: "15rem" },
      formatter: (_: string, row: Task) => {
        return (
          <Link
            sx={{
              padding: 0,
              fontSize: 13,
              fontWeight: "normal",
            }}
            href={`/matters/${row.matter?._id}/overview`}
            title={row.matter.case_name || ""}
            onClick={() => navigate(`/matters/${row.matter?._id}/overview`)}
          >
            <Typography noWrap={true}>{row.matter.case_name || "-"}</Typography>
          </Link>
        );
      },
    },
  ];

  const allAttorneys = attorneysAPI.data || [];
  const matterData = matterAPI.data;

  const matterMembers = allAttorneys.filter(
    (a) =>
      a._id === matterData?.responsible_lawyer?._id ||
      a._id === matterData?.responsible_supervisor?._id ||
      a._id === matterData?.paralegal?._id ||
      a._id === matterData?.secretary?._id
  );

  const otherMembers = allAttorneys.filter(
    (a) => !matterMembers.some((d) => d._id === a._id)
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>, row?: Task) => {
    const newCategory = event.currentTarget.getAttribute("value");
    if (newCategory) {
      row && taskUpdate({ category: newCategory }, row._id);
      handleClose();
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Page title="Tasks" sx={{ height: "100%" }}>
      <Container maxWidth={false} sx={{ height: "100%" }}>
        <YaoTable
          isLoading={tasksQuery.isLoading}
          isPagging={tasksQuery.isFetching}
          afterRows={(selected) => (
            <>
              <TableRow ref={tasksInfiniteQuery.ref} style={{ height: 0 }} />
            </>
          )}
          label="Tasks"
          labelNoData="No tasks at this point"
          imgNoData="/assets/illustrations/illustration_empty_tasks.svg"
          rows={rows}
          rowClick={(row) => setCurrentTaskSelected(row as Task)}
          tableLayoutFixed={true}
          // @ts-ignore
          columns={[
            ...(showMatter ? matterColumns : []),
            ...getColumns({
              setUpdatingTask,
              setCommentPanel,
              updateStatus,
              taskUpdate,
              updateDueDate,
              debounce,
              matterMembers,
              otherMembers,
              matterId,
              anchorEl,
              statusOpen,
              handleClose,
              handleClick,
              currentTaskSelected,
              pathname,
            }),
          ]}
          sx={{ mt: 2.5 }}
          toolbar={
            <>
              <FormControl className="matter-control">
                <TextField
                  id="text-search"
                  label="Search by heading"
                  variant="outlined"
                  value={inputValue}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                  }}
                  InputProps={{
                    sx: { p: 0 },
                  }}
                />
              </FormControl>
              <FormControl className="matter-control">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    name="My Tasks"
                    checked={myTasksFilter}
                    onChange={(e) => handleTaskFilter(e.target.checked)}
                  />
                  <Typography sx={{ pl: "1px", pr: "4px" }} variant="caption">
                    My Tasks
                  </Typography>
                </Box>
              </FormControl>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  name="task_status"
                  value={TaskStatus.TO_DO}
                  onChange={handleStatusFilter}
                  checked={filters.status?.includes(TaskStatus.TO_DO)}
                />
                <Typography sx={{ pl: "1px", pr: "4px" }} variant="caption">
                  To-do
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  name="task_status"
                  value={TaskStatus.IN_PROGRESS}
                  onChange={handleStatusFilter}
                  checked={filters.status?.includes(TaskStatus.IN_PROGRESS)}
                />
                <Typography sx={{ pl: "1px", pr: "4px" }} variant="caption">
                  In progress
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  name="task_status"
                  value={TaskStatus.COMPLETED}
                  onChange={handleStatusFilter}
                  checked={filters.status?.includes(TaskStatus.COMPLETED)}
                />
                <Typography sx={{ pl: "1px", pr: "4px" }} variant="caption">
                  Completed
                </Typography>
              </Box>

              <Button
                style={{ marginLeft: "24px" }}
                startIcon={
                  <CustomIcon
                    name="filter"
                    sx={{ padding: 0.2 }}
                    color={palette.yao.grey[6]}
                  />
                }
                onClick={() =>
                  NiceModal.show<TaskFilterForm>(Dialog, {
                    Content: TaskFilters,
                    params: { filters },
                  }).then((newFilters) => {
                    setFilters(newFilters);
                  })
                }
              >
                Filter tasks
              </Button>
              {pathname !== PATH_TASKS.root && (
                <Button
                  style={{ marginLeft: "24px" }}
                  startIcon={
                    <CustomIcon
                      name="add"
                      sx={{ padding: 0.2 }}
                      color={palette.yao.grey[6]}
                    />
                  }
                  variant="outlined"
                  onClick={() => setAddForm(true)}
                >
                  New Task
                </Button>
              )}
            </>
          }
          rowOptions={[
            {
              action: handleRemoveTask,
              label: "Remove",
              icon: "eva:trash-2-outline",
            },
          ]}
        />

        {updatingTask && (
          <Drawer
            anchor="right"
            open={!!updatingTask}
            onBackdropClick={() => setUpdatingTask(null)}
            BackdropProps={{
              invisible: true,
            }}
          >
            <UpdateTask
              task={updatingTask}
              setUpdatingTask={setUpdatingTask}
              callTasksAPI={refetchQuery}
            />
          </Drawer>
        )}

        <Drawer
          anchor="right"
          open={!!commentPanel}
          onBackdropClick={() => setCommentPanel(null)}
          BackdropProps={{
            invisible: true,
          }}
        >
          <TaskCommentsList
            task={commentPanel}
            setCommentPanel={setCommentPanel}
          />
        </Drawer>

        <Drawer
          anchor="right"
          open={addForm}
          onBackdropClick={() => setAddForm(false)}
          BackdropProps={{
            invisible: true,
          }}
        >
          <AddNewTask
            matterId={matterId as string}
            callTasksAPI={refetchQuery}
            setAddForm={setAddForm}
          />
        </Drawer>
      </Container>
    </Page>
  );
}
