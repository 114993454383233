import axios from "src_common/utils/axios";
import { formatError } from "src_common/utils/misc";
import {Department} from "./departments";

export enum NarrativeType {
  PAYMENT = 'PAYMENT',
  INVOICE = 'INVOICE',
}

export type Narrative = {
  department: Partial<Department>;
  law_firm: string;
  text: string;
  type: NarrativeType;
  created_at?: Date;
  updated_at?: Date;
  _id: string
};

export type CreateNarrative = {
  text: string;
  type?: NarrativeType;
  department: string;
}

export interface NarrativeQueryRequest {
  department?: string;
  type?: NarrativeType;
  search: string;
  limit?: number;
  page?: number;
}

export interface NarrativeQueryResponse {
  rows: Narrative[];
  limit: number;
  search: string;
  page: number;
}

export async function createNarrative(form: CreateNarrative): Promise<Narrative> {
  try {
    const {data: narrative} = await axios.post<Narrative>("/narratives", form);
    return narrative;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function getNarrative(
    params: NarrativeQueryRequest,
    incrementPage = false
): Promise<NarrativeQueryResponse> {
    const { data: response } = await axios.get<NarrativeQueryResponse>("narratives", {
      params: incrementPage ? {...params, page: Number(params.page) + 1} : params,
    })
    return response
}

export async function deleteNarrative(id: string): Promise<void> {
  try {
    await axios.delete(`/narratives/${id}`);
  } catch (e) {
    throw new Error(formatError(e));
  }
}
