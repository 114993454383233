import { ReactElement, useEffect, useMemo, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useAPI } from "src_common/hooks/useAPI";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import palette from "src_common/theme/palette";
import YaoTable from "src_common/components/yao-table";
import { fDate } from "src_common/utils/formatTime";
import {
  searchCorrespondences,
  CorrespondenceCategory,
  CorrespondenceMailAttachments,
  Correspondence,
} from "src_lawfirm/api/correspondence";
import { getAttachmentIconName } from "src_common/utils/fileHelpers";
import CustomIcon from "src_common/components/custom-icon";
import { TextField } from "@mui/material";
import { useDownloadFileFromS3 } from "src_common/components/yao-form-fields";
import { CorrespondencePreviewV2Component } from "./CorrespondencePreviewV2.component";

const TABLE_HEAD = [
  {
    id: "type",
    label: "",
    formatter: (type: any) => <Box sx={{ width: "28px" }}>{type}</Box>,
  },
  {
    id: "filename",
    label: "Attachment",
    formatter: (filename: string) => (
      <Typography noWrap sx={{ width: "390px" }} title={filename}>
        {filename}
      </Typography>
    ),
  },
  {
    id: "author",
    label: "Author",
    formatter: (author: string) => (
      <Typography noWrap sx={{ width: "200px" }} title={author}>
        {author}
      </Typography>
    ),
  },
  {
    id: "created_at",
    label: "created At",
    formatter: (created_at: Date) => (
      <Box sx={{ width: "70px" }}>{fDate(created_at)}</Box>
    ),
  },
];

interface IFile {
  readonly file: File;
  readonly filename: string;
}

export interface IFileList {
  _id: string;
  filename: string;
  key: string | undefined;
  type: ReactElement;
  author: string;
  created_at: Date;
  correspondenceId?: string;
}

let filesToAttach: IFile[] = [];
interface IProps {
  matterId: string | undefined;
  attachments: IFile[];
  setAttachments: Function;
  visible: boolean;
  handleClose: Function;
  paymentNotification?: {
    files: Array<IFileList>;
  };
  onChoose?: (val: any) => void;
}

let filesList: IFileList[] = [];

export const ListCorrespondenceFilesModal = ({
  matterId,
  attachments,
  setAttachments,
  visible,
  handleClose,
  onChoose,
  paymentNotification,
}: IProps) => {
  const [fileListFiltered, setFileListFiltered] = useState<IFileList[]>([]);
  const [fileNameSearch, setFileNameSearch] = useState("");
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [correspondence, setCorrespondence] = useState<string | undefined>(
    undefined
  );
  const [isLoadingAttachments, setIsLoadingAttachments] =
    useState<boolean>(false);
  const { download } = useDownloadFileFromS3();
  const getCorrespondencesAPI = useAPI(searchCorrespondences);

  useEffect(() => {
    filesToAttach.length = 0;
    getCorrespondencesAPI.invoke({
      matter_id: matterId,
      category: [CorrespondenceCategory.DOCUMENT, CorrespondenceCategory.EMAIL],
      limit: -1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    filesList.length = 0;
    getCorrespondencesAPI.data?.length &&
      getCorrespondencesAPI.data.forEach((row) => {
        if (row.category === CorrespondenceCategory.DOCUMENT) {
          filesList.push({
            _id: row.key || "",
            correspondenceId: row._id,
            filename: row.title || "<no file name>",
            key: row.key,
            type: (
              <CustomIcon
                name={getAttachmentIconName(row.key?.split(".").pop()) || ""}
                sx={{
                  height: "27px",
                  width: "27px",
                }}
              />
            ),
            author: row.author?.name || "<no author>",
            created_at: row.created_at,
          });
        }
        if (row.category === CorrespondenceCategory.EMAIL) {
          row.attachments?.length &&
            row.attachments.forEach((attachment) => {
              !filesList.find((file) => file.key === attachment.key) &&
                filesList.push({
                  _id: attachment.key,
                  key: attachment.key,
                  correspondenceId: row._id,
                  filename: attachment.filename || "<no file name>",
                  type: (
                    <CustomIcon
                      name={
                        getAttachmentIconName(
                          attachment.key?.split(".").pop()
                        ) || ""
                      }
                      sx={{
                        height: "27px",
                        width: "27px",
                      }}
                    />
                  ),
                  author:
                    (row.from_emails?.length && row.from_emails[0].name) ||
                    "<no author>",
                  created_at: row.created_at,
                });
            });
        }
      });

    let preparedFilesList = [...filesList];
    if (paymentNotification) {
      paymentNotification.files.forEach((item) => {
        preparedFilesList = filesList.filter(
          (file) => file.correspondenceId !== item._id
        );
      });
    }
    setFileListFiltered(preparedFilesList);
  }, [getCorrespondencesAPI.data]);

  useEffect(() => {
    setFileListFiltered(
      fileNameSearch
        ? filesList.filter((file) =>
            file.filename.toLowerCase().includes(fileNameSearch.toLowerCase())
          )
        : filesList
    );
  }, [fileNameSearch]);

  const loadCorrespondenceAttachments = (
    files: CorrespondenceMailAttachments[]
  ) => {
    if (!files) return;
    setIsLoadingAttachments(true);
    Promise.all(
      files.map(async (attachment) => {
        const urlS3: string = (await download(attachment.key!)) || "";
        const response = await fetch(urlS3);
        const blob = await response.blob();
        const file = new File([blob], attachment.key, {
          type: blob.type,
        });
        filesToAttach.push({ file, filename: attachment.filename });
        setAttachments([...attachments, ...filesToAttach]);
      })
    ).then(() => {
      setIsLoadingAttachments(false);
      handleClose(false);
    });
  };

  const attach = () => {
    const files: CorrespondenceMailAttachments[] = [];
    for (let key of selectedFiles) {
      const file = filesList.find((file) => file.key === key);
      if (file) {
        files.push({
          filename: file.filename,
          key: file.key || "",
        });
      }
    }
    if (paymentNotification) {
      if (paymentNotification && onChoose) {
        let result: IFileList[] = [];

        selectedFiles.forEach((val, idx) => {
          filesList.forEach((f) => {
            if (f.key === val) {
              result.push(f);
            }
          });
        });
        paymentNotification.files.forEach((val, idx) => {
          filesList.forEach((f) => {
            if (f.key === val.key) {
              result.push(f);
            }
          });
        });

        onChoose(result);
      }
      handleClose(false);
      return;
    }
    loadCorrespondenceAttachments(files);
  };

  const defaultSelected = useMemo(
    () =>
      paymentNotification?.files
        ? paymentNotification.files.map((item) => item._id)
        : [],
    [paymentNotification]
  );

  return (
    <Dialog
      open={visible}
      onClose={() => handleClose(false)}
      scroll="paper"
      PaperProps={{
        style: {
          minWidth: "850px",
          minHeight: "700px",
          maxHeight: "700px",
        },
      }}
    >
      <Box sx={{ display: "flex", paddingBottom: 0 }}>
        <IconButton onClick={() => handleClose(false)}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 20px 10px 20px",
        }}
      >
        <Typography
          color={palette.yao.primary[2]}
          fontSize="21px"
          fontWeight="600"
        >
          Correspondence
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          placeholder="Search file name"
          sx={{ maxWidth: 300 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon color="primary" />
              </InputAdornment>
            ),
          }}
          value={fileNameSearch}
          onChange={(e) => setFileNameSearch(e.target.value)}
        />
      </Box>
      {!correspondence ? null : (
        <CorrespondencePreviewV2Component
          correspondenceId={correspondence}
          onClose={() => {
            setCorrespondence(undefined);
          }}
        />
      )}
      <DialogContent sx={{ margin: 0, padding: "0 12px 0 12px" }}>
        <YaoTable
          rows={fileListFiltered}
          columns={TABLE_HEAD}
          isLoading={getCorrespondencesAPI.loading}
          selectable
          defaultSelected={defaultSelected}
          rowClick={(props) => {
            setCorrespondence(props.correspondenceId);
          }}
          labelNoData="No correspondences files found"
          imgNoData="/assets/illustrations/illustration_empty_content.svg"
          afterRows={(e) => setSelectedFiles(e)}
        />
      </DialogContent>
      <Divider />
      <Box
        sx={{
          padding: "10px 14px 10px 14px",
        }}
      >
        <DialogActions style={{ padding: 0 }}>
          <Button color="inherit" onClick={() => handleClose(false)}>
            Cancel
          </Button>

          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ width: "95px" }}
            loading={isLoadingAttachments}
            onClick={attach}
          >
            Choose
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
