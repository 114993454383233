import {
  createRef,
  DragEvent,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useSnackbar } from "notistack";
import {
  AppBar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Slide,
  Stack,
  StepLabel,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import {
  FormFieldTypeOptions,
  FormTemplate,
  FormTemplateDto,
  FormTemplateModel,
  FormType,
} from "../../@interfaces/forms";
import { FormProvider } from "../hook-form";

import {
  SupportedFileTypes,
  useDownloadFileFromS3,
  useUploadFileToS3,
  YAOFieldAutocomplete,
  YAOFieldAutocompleteMultipleWithSelectAll,
  YAOFieldAutocompleteOption,
  YAOFieldAutocompleteOptionObject,
  YAOFieldText,
} from "../yao-form-fields";

import { YaoFormFieldLabel } from "../yao-form/YaoForm";
import { DropResult } from "react-beautiful-dnd";
import { getCasetype } from "src_lawfirm/api/case-types";
import _ from "lodash";
import palette from "src_common/theme/palette";
import LoadingButton from "@mui/lab/LoadingButton";
import { copyToClipboard } from "src_common/utils/clipboard";
import { formatError } from "../../utils/misc";
import { useCheatSheet } from "../cheat-sheet/useCheatSheet";
import { useAPI } from "src_common/hooks/useAPI";
import { getFormTemplates } from "src_lawfirm/api/forms";
import { Stepper } from "@mui/material";
import { Step } from "@mui/material";
import { InfoIcon } from "src_common/theme/overrides/CustomIcons";

const GUID = () =>
  Date.now().toString(36) + Math.random().toString(36).substring(2);

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type DialogProps = {
  template?: FormTemplate;
  inLawFirm?: boolean;
  formType?: FormType;
  saveFn: (form: FormTemplateDto, id?: string) => Promise<FormTemplate>;
};

const SUPPORTED_FORMATS: SupportedFileTypes[] = [
  SupportedFileTypes.DOC,
  SupportedFileTypes.DOCX,
  SupportedFileTypes.PDF,
  SupportedFileTypes.XLS,
  SupportedFileTypes.XLXS,
  SupportedFileTypes.MACRO,
] as const;

const PrecedentTemplateDialog = NiceModal.create<DialogProps>(
  ({ formType = FormType.PRECEDENT, ...params }) => {
    const modal = useModal();
    const { supportedFormats, acceptFormats } = useMemo(() => {
      if (formType === FormType.LETTERHEAD) {
        return {
          supportedFormats: [SupportedFileTypes.PDF],
          acceptFormats: `.${SupportedFileTypes.PDF}`,
        };
      }
      return {
        supportedFormats: SUPPORTED_FORMATS,
        acceptFormats: SUPPORTED_FORMATS.map((item) => `.${item}`).join(","),
      };
    }, [formType]);

    const getFormsAPI = useAPI<FormTemplate[]>(getFormTemplates);
    const { forPrecedent, extractFieldKey } = useCheatSheet();
    const [loader, setLoader] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();
    const upload = useUploadFileToS3(!params.inLawFirm);
    const { directDownload } = useDownloadFileFromS3(!params.inLawFirm);
    const [mode] = useState<"create" | "edit">(
      !!params.template ? "edit" : "create"
    );
    const [caseType, setCaseType] = useState<YAOFieldAutocompleteOption[]>([]);
    const [uploading, setUploading] = useState<boolean>(false);
    const [hasFile, setHasFile] = useState<boolean>(false);
    const inputRef = createRef<HTMLInputElement>();
    const [formKey, setFormKey] = useState("");

    useEffect(() => {
      if (formType === FormType.PRECEDENT) {
        getFormsAPI.invoke({
          form_type: FormType.LETTERHEAD,
        });
      }
    }, [formType]);

    const currentLetterheads = useMemo<YAOFieldAutocompleteOption[]>(
      () =>
        getFormsAPI.data && getFormsAPI.data?.length > 0
          ? getFormsAPI.data.map((letterhead) => ({
              value: letterhead._id,
              label: letterhead.title,
            }))
          : [],
      [getFormsAPI.data]
    );

    const methods = useForm<FormTemplateModel>({
      mode: "onChange",
      defaultValues: {
        title: "",
        description: "",
        fields: [{ _id: GUID(), name: "", type: undefined }],
        case_types: [],
        document_template_path: null,
      },
    });

    const formFields = useFieldArray({
      name: "fields",
      control: methods.control,
    });

    const watchForDefaults = methods.watch(["title", "fields"]);

    useEffect(() => {
      if (!params.inLawFirm) {
        return;
      }

      (async () => {
        try {
          const respons = await getCasetype(undefined);
          setCaseType(respons.map((c) => ({ label: c.title, value: c._id })));
        } catch (e) {
          setCaseType([]);
        }
      })();
    }, []);

    useEffect(() => {
      if (!params.template) {
        return;
      }
      if (formType === FormType.PRECEDENT && !getFormsAPI?.data) {
        return;
      }
      const document_template_path =
        (params.template.document_template_path || "").length > 0
          ? params.template.document_template_path
          : null;
      setHasFile(document_template_path !== null);
      setFormKey(params.template.label);

      methods.reset({
        title: params.template.title,
        description: params.template.description,
        fields: Array.isArray(params.template.fields)
          ? params.template.fields.map((f) => ({
              _id: GUID(),
              name: f.name,
              type: FormFieldTypeOptions.find((o) => o.value === f.type),
            }))
          : [],
        case_types: Array.isArray(params.template.case_types)
          ? params.template.case_types.map((c) => ({
              label: c.case_type,
              value: c.case_type_id,
            }))
          : [],
        letterhead:
          formType !== FormType.PRECEDENT
            ? undefined
            : (currentLetterheads as YAOFieldAutocompleteOptionObject[])?.find(
                (item) => item.value === params.template?.letterhead && item
              ),
        document_template_path,
      });
    }, [params.template, getFormsAPI?.data, formType]);

    useEffect(() => {
      const subscription = methods.watch((value, { name, type, ...rest }) => {
        if (name === "title") {
          setFormKey(extractFieldKey(methods.getValues("title")));
        }
      });
      return () => subscription.unsubscribe();
    }, [watchForDefaults]);

    const handleCloseForm = useCallback(() => {
      modal.hide();
    }, [modal]);

    const onSubmit: SubmitHandler<FormTemplateModel> = async (data) => {
      try {
        setLoader(true);

        const form: FormTemplateDto = {
          form_type: formType,
          title: data.title,
          description: data.description,
          case_types: data.case_types.map((c) => c.value),
          letterhead: data?.letterhead?.value,
          document_template_path: data.document_template_path,
        };

        const response = await params.saveFn(
          form,
          params.template?._id || undefined
        );
        modal.resolve(response);
        modal.hide();
      } catch (error) {
        enqueueSnackbar(formatError(error), { variant: "error" });
      } finally {
        setLoader(false);
      }
    };

    const onDragEnd = (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      if (result.destination.index === result.source.index) {
        return;
      }

      formFields.move(result.source.index, result.destination.index);
    };

    const onDrop = async (ev: DragEvent<HTMLDivElement>) => {
      ev.preventDefault();

      const files: File[] = ev.dataTransfer.items
        ? [...ev.dataTransfer.items]
            .filter((item) => item.kind === "file")
            .map((item) => item.getAsFile())
        : [...ev.dataTransfer.files];

      if (
        !files.length ||
        !supportedFormats.includes(
          files[0].name.split(".").pop()?.toLowerCase() as SupportedFileTypes
        )
      ) {
        return Promise.resolve(null);
      }

      setUploading(true);
      return await upload(files[0]);
    };

    const onSelect = async (fileList: FileList | null) => {
      try {
        const files = Array.from(fileList || []);
        const isAllowed = supportedFormats.includes(
          files[0].name.split(".").pop()?.toLowerCase() as SupportedFileTypes
        );

        if (!files.length || !isAllowed) {
          return;
        }

        setUploading(true);
        upload(files[0]).then((file) => handleFileUploaded(file as any));
      } catch (error) {
        console.error("Error on selecting file");
      }
    };

    const handleFileUploaded = (
      file: {
        key: string;
        title: string;
      } | null
    ) => {
      setUploading(false);
      if (!file) {
        methods.setValue("document_template_path", null);
        setHasFile(false);
        return;
      }
      setHasFile(true);
      methods.setValue("document_template_path", file.key);
    };

    const handleDownload = async () => {
      const file = methods.getValues("document_template_path");
      if (!file?.length) {
        return;
      }
      await directDownload(file);
    };

    const handleCopy = (value: string) => {
      copyToClipboard(value, inputRef, (err) => {
        if (err) {
          enqueueSnackbar("Try again", { variant: "error" });
          return;
        }
        enqueueSnackbar("Field key copied to clipboard", {
          variant: "success",
        });
      });
    };

    const openCheatSheet = () => {
      forPrecedent();
    };

    const dialogTitle: Record<FormType, string> = {
      [FormType.FORM]: "Form",
      [FormType.PRECEDENT]: "Precedent",
      [FormType.LETTERHEAD]: "Letterhead",
    };

    const steps = [
      "Create your document with headers and footers",
      "Export as PDF and upload here",
      "Link it to a precedent and voila",
    ];

    return (
      <>
        <Dialog
          maxWidth="lg"
          fullWidth
          open={modal.visible}
          scroll="paper"
          disableEscapeKeyDown={true}
          onClose={handleCloseForm}
          TransitionComponent={Transition}
          TransitionProps={{
            onExited: () => modal.remove(),
          }}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {mode === "create" ? "Create new" : "Edit"}{" "}
                {dialogTitle[formType]} Template
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ p: 1, height: "80vh" }}>
            {formType === FormType.LETTERHEAD && (
              <>
                <Typography
                  width="100%"
                  textAlign="center"
                  fontSize="21px"
                  lineHeight="24px"
                  fontWeight={600}
                  mb={2}
                  sx={{ color: "#0D394D" }}
                >
                  How to create your letterhead template
                </Typography>
                <Box sx={{ width: "100%" }}>
                  <Stepper activeStep={-1} alternativeLabel>
                    {steps.map((label) => (
                      <Step active={true} key={label}>
                        <StepLabel>
                          <Typography
                            width="80%"
                            marginLeft="10%"
                            textAlign="center"
                            fontSize="14px"
                            lineHeight="22px"
                            fontWeight={400}
                            sx={{ color: "#646D76" }}
                          >
                            {label}
                          </Typography>{" "}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </>
            )}

            <FormProvider
              methods={methods}
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              <Paper variant="outlined" sx={{ mt: 1, p: 2 }}>
                <Typography variant="h4">
                  {dialogTitle[formType]} identification
                </Typography>
                <Grid container rowSpacing={1} columnSpacing={1}>
                  <Grid
                    item
                    md={formType === FormType.PRECEDENT && 6}
                    sm={formType === FormType.PRECEDENT && 6}
                    xs={12}
                  >
                    <YaoFormFieldLabel name="title" label="Title" required />
                    <YAOFieldText
                      name="title"
                      type="text"
                      placeholder={`Enter ${dialogTitle[formType]} title`}
                      rules={{
                        required: `${dialogTitle[formType]} title is required`,
                      }}
                    />
                  </Grid>
                  {formType === FormType.PRECEDENT && (
                    <Grid
                      item
                      md={formType === FormType.PRECEDENT && 6}
                      sm={formType === FormType.PRECEDENT && 6}
                      xs={12}
                    >
                      <Tooltip
                        placement="top-start"
                        title="Upload a pdf with header and footer from the Letterhead tab"
                      >
                        <Box display={"flex"} alignItems="center">
                          <YaoFormFieldLabel
                            name="letterhead"
                            label="Letterhead"
                          />
                          <InfoIcon
                            sx={{ fontSize: "14px", margin: "0 8px 5px" }}
                            color="primary"
                          />
                        </Box>
                      </Tooltip>

                      <YAOFieldAutocomplete
                        name="letterhead"
                        label=""
                        placeholder="Choose"
                        options={currentLetterheads}
                      />
                    </Grid>
                  )}
                  {formType !== FormType.LETTERHEAD && (
                    <Grid item xs={12}>
                      <Typography variant="caption">
                        Form key: <b>{formKey}</b>
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <YaoFormFieldLabel name="title" label="Description" />
                    <YAOFieldText
                      name="description"
                      type="text"
                      multiline
                      minRows={2}
                      maxRows={4}
                      placeholder={`Enter ${dialogTitle[formType]} description`}
                    />
                  </Grid>
                </Grid>
              </Paper>

              {params.inLawFirm && formType !== FormType.LETTERHEAD && (
                <Paper variant="outlined" sx={{ mt: 1, p: 2 }}>
                  <Typography variant="h4">
                    Allocate to Case Type / multiple Case Types
                  </Typography>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={1}
                    sx={{ mt: 1 }}
                  >
                    <Grid item xs={12}>
                      <YAOFieldAutocompleteMultipleWithSelectAll
                        name="case_types"
                        label=""
                        placeholder="Choose"
                        options={caseType}
                        getOptionLabel={(v) => _.get(v, "label")}
                        customClassname="multi-tag"
                        filterSelectedOptions={true}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              )}
              <Paper variant="outlined" sx={{ mt: 1, p: 2 }}>
                <Stack direction="row" spacing={1}>
                  <Typography variant="h4" sx={{ flexGrow: 1, flexShrink: 1 }}>
                    {formType !== FormType.LETTERHEAD
                      ? "Document template"
                      : "Letterhead PDF template"}
                  </Typography>
                  {formType !== FormType.LETTERHEAD && (
                    <Button variant="outlined" onClick={() => openCheatSheet()}>
                      Cheatsheet
                    </Button>
                  )}
                </Stack>

                <Box
                  sx={{
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    border: "1px dashed #D8E4E4",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 2,
                    display: "flex",
                    padding: 2,
                    marginTop: 2,
                  }}
                  onDrop={(e) => {
                    onDrop(e).then((file) => handleFileUploaded(file as any));
                  }}
                  onDragOver={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  {hasFile && (
                    <>
                      <Typography sx={{ fontSize: 12, fontWeight: 700 }}>
                        You uploaded a document, you can Drag & Drop another
                        document here to modify
                      </Typography>
                      <Typography
                        color="gray"
                        sx={{ fontSize: 12, fontWeight: 700 }}
                      >
                        OR
                      </Typography>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          variant="text"
                          color="secondary"
                          onClick={handleDownload}
                          sx={{ mr: 1 }}
                        >
                          Download file
                        </Button>
                        <Button
                          variant="text"
                          color="error"
                          onClick={() => {
                            setHasFile(false);
                            methods.setValue("document_template_path", null);
                          }}
                          sx={{ mr: 1 }}
                        >
                          Remove current file
                        </Button>
                        <LoadingButton
                          loading={uploading}
                          variant="contained"
                          component="label"
                        >
                          Upload another
                          {!uploading && (
                            <input
                              hidden
                              accept={acceptFormats}
                              onChange={(e) => onSelect(e.target.files)}
                              type="file"
                            />
                          )}
                        </LoadingButton>
                      </div>
                    </>
                  )}
                  {!hasFile && (
                    <>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <img
                          src="/assets/icons/flags/ic_folder.png"
                          alt="Folder"
                        />
                      </div>
                      <Typography sx={{ fontSize: 12, fontWeight: 700 }}>
                        Drag & drop your document here
                      </Typography>

                      <Typography
                        color="gray"
                        sx={{ fontSize: 12, fontWeight: 700 }}
                      >
                        OR
                      </Typography>

                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <LoadingButton
                          loading={uploading}
                          variant="contained"
                          component="label"
                        >
                          Browser file
                          {!uploading && (
                            <input
                              hidden
                              accept={acceptFormats}
                              onChange={(e) => onSelect(e.target.files)}
                              type="file"
                            />
                          )}
                        </LoadingButton>
                      </div>
                    </>
                  )}
                </Box>
              </Paper>
            </FormProvider>
            <input
              hidden
              type="text"
              ref={inputRef}
              readOnly
              style={{ width: 0, height: 0, visibility: "hidden" }}
            />
          </DialogContent>
          <DialogActions sx={{ p: 1 }}>
            <Typography sx={{ fontSize: 11, color: palette.yao.primary[3] }}>
              <span
                style={{ color: palette.yao.secondary[2], fontWeight: 600 }}
              >
                *
              </span>{" "}
              Mandatory fields
            </Typography>
            <Box flexGrow={1} flexShrink={1} />
            <Button variant="text" onClick={handleCloseForm}>
              cancel
            </Button>
            <Button
              variant="contained"
              onClick={methods.handleSubmit(onSubmit)}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}
          open={loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
);

export default PrecedentTemplateDialog;
