import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import {useSnackbar} from "notistack";
import React, {ChangeEvent, SyntheticEvent, useCallback, useContext, useEffect, useMemo, useState,} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {FormProvider} from "src_common/components/hook-form";
import {
    YAOFieldAutocomplete,
    YAOFieldAutocompleteOption,
    YAOFieldDatePicker,
    YAOFieldText,
} from "src_common/components/yao-form-fields";
import {NumberFormatCustom, YAOFieldCurrency,} from "src_common/components/yao-form-fields/YAOFieldCurrency";
import {YaoFormFieldLabel} from "src_common/components/yao-form/YaoForm";
import {useAPI} from "src_common/hooks/useAPI";
import {fCurrencyWithFormat} from "src_common/utils/formatNumber";
import {fFullDate} from "src_common/utils/formatTime";
import {formatError} from "src_common/utils/misc";
import {Attorney, getAttorneys} from "src_lawfirm/api/attorneys";
import {BankAccount, BankAccountTypes, getBankAccount,} from "src_lawfirm/api/bank-account";
import {getMatter} from "src_lawfirm/api/matters";
import {InvoicedFilter, searchTimeEntries, TimeEntry,} from "src_lawfirm/api/time-entries";
import {invoiceCreate} from "../../api/invoices";
import {contactNameResolver} from "../contacts/useContacts";
import {addDays} from "date-fns";
import Decimal from "decimal.js";
import "./invoice.css";
import palette from "src_common/theme/palette";
import Iconify from "src_common/components/Iconify";
import {Ledger, searchLedgers} from "src_lawfirm/api/ledgers";
import {useInfiniteQuery, useQueryClient} from "@tanstack/react-query";
import {useInfiniteScrollQuery} from "../../../src_common/hooks/useInfiniteScrollQuery";
import {LawFirmContext} from "../law-firm/LawFirmContext";
import {createNarrative, getNarrative, Narrative, NarrativeQueryRequest, NarrativeType} from "../../api/narratives";
import _ from "lodash";
import {LibraryAdd} from "@mui/icons-material";

Decimal.set({ precision: 10, rounding: 2 });

type InvoiceDialogProps = {
  matter?: string;
  entries?: TimeEntry[];
  allWip?: boolean;
};

type AutoComplete = {
  value: string;
  label: string;
  id: string | null;
  vat?: boolean;
  ledger?: Ledger;
};

type InvoiceForm = {
  billing_amount: number;
  vat_percentage: number;
  less_paid_on_account: number;
  matter: AutoComplete;
  debit_account: AutoComplete;
  receiving_account: AutoComplete;
  solicitor: AutoComplete;
  client: AutoComplete;
  due_date: Date | null;
  invoice_date: Date | null;
  narrative: string;
  payable_by: string;
  include_wip: boolean;
};

type AutoSelectModel = {
  matters: AutoComplete[];
  clients: AutoComplete[];
  solicitors: AutoComplete[];
  debit_accounts: AutoComplete[];
  receiving_accounts: AutoComplete[];
};

export const InvoiceDialog = NiceModal.create<InvoiceDialogProps>(
  ({ matter, entries = [], allWip = false }) => {
    const lawFirmContext = useContext(LawFirmContext);
    const modal = useModal();
    const { enqueueSnackbar } = useSnackbar();
    const attorneysAPI = useAPI(getAttorneys);
    const bankAccountAPI = useAPI(getBankAccount);
    const ledgersAPI = useAPI(searchLedgers);
    const [loading, setLoading] = useState<boolean>(false);
    const [draft, setDraft] = useState<boolean>(false);
    const [options, setOptions] = useState<AutoSelectModel>({
      matters: [],
      clients: [],
      solicitors: [],
      debit_accounts: [],
      receiving_accounts: [],
    });
    const [timeEntries, setTimeEntries] = useState<TimeEntry[]>(entries);
    const [timeEntriesSubtotal, setTimeEntriesSubtotal] = useState<number>(0);
    const [hasTimeEntries, setHasTimeEntries] = useState<boolean>(
      entries.length > 0
    );
    const [disbursementsSubtotal, setDisbursementsSubtotal] =
      useState<number>(0);
    const [disbursements, setDisbursements] = useState<AutoComplete[]>([]);
    const [disbursementsIds, setDisbursementsIds] = useState<string[]>([]);
    const [otherFees, setOtherFees] = useState<AutoComplete[]>([]);
    const [firmFees, setFirmFees] = useState<AutoComplete[]>([]);
    const [otherFeesSubtotal, setOtherFeesSubtotal] = useState<number>(0);
    const [firmFeesSubtotal, setFirmFeesSubtotal] = useState<number>(0);
    const [billingAmountTotal, setBillingAmountTotal] = useState<number>(0);
    const [vatPercentage, setVatPercentage] = useState<number>(
      lawFirmContext.getTaxPercentage()
    );
    const [totalDue, setTotalDue] = useState<number>(0);
    const [loaded, setLoaded] = useState<boolean>(!allWip);
    const [departmentId, setDepartmentId] = useState<string>('');
    const [inputNarrativeValue, setInputNarrativeValue] = useState<string>("");

    const [filters, setFilters] = useState<NarrativeQueryRequest>({
        type: NarrativeType.INVOICE,
        department: undefined,
        search: "",
        limit: 20,
        page: 0,
    });

    const currInfo = useMemo(
      () => lawFirmContext.getCurrency(),
      [lawFirmContext]
    );

    const wipEntries = useInfiniteQuery(
      [matter, "wip-entries"],
      ({ pageParam }) =>
        searchTimeEntries({
          page: pageParam,
          size: 1000,
          matter,
          invoiced: InvoicedFilter.Wip,
        }),
      {
        getNextPageParam: (lastPage) => lastPage.next,
      }
    );
    const { ref } = useInfiniteScrollQuery({
      fetch:
        !wipEntries.isFetching && wipEntries.hasNextPage
          ? wipEntries.fetchNextPage
          : undefined,
    });

    const queryClient = useQueryClient();

    const narrativesQuery = useInfiniteQuery(
      ["narratives", filters, departmentId],
      ({ pageParam }) =>getNarrative({
          ...filters,
          department: departmentId,
          page: pageParam,
      }),
      {
          enabled: !!departmentId,
          getNextPageParam: (lastPage, allPages) =>
              Array.isArray(lastPage.rows) && !lastPage.rows.length? undefined : Number(lastPage.page || 0) + 1,
      },
    );

    const refetchQuery = async () => {
      await queryClient.invalidateQueries(["narratives", filters, departmentId]);
    };

    const methods = useForm<InvoiceForm>({
      mode: "onChange",
      defaultValues: {
        due_date: addDays(new Date(), 14),
        invoice_date: new Date(),
        vat_percentage: lawFirmContext.getTaxPercentage(),
        less_paid_on_account: 0,
        payable_by: "",
        billing_amount: 0,
        include_wip: false,
      },
    });

    const watchForDefaults = methods.watch([
      "billing_amount",
      "vat_percentage",
      "less_paid_on_account",
    ]);

    useEffect(() => {
      bankAccountAPI.invoke(undefined);
      attorneysAPI.invoke(undefined, (data) => {
        setOptions((old) => ({
          ...old,
          solicitors: ((data as Attorney[]) || []).map((a) => ({
            value: a._id,
            label: `${a.name} ${a.surname}`,
          })) as AutoComplete[],
        }));
      });
      getMatter(matter as string).then((m) => {
        const nMatters = [
          {
            value: m._id,
            label: m.case_name,
          },
        ] as AutoComplete[];
        methods.setValue("matter", nMatters[0]);
        if (!!m.responsible_lawyer) {
          methods.setValue("solicitor", {
            value: m.responsible_lawyer._id,
            label: `${m.responsible_lawyer.name} ${m.responsible_lawyer.surname}`,
          } as AutoComplete);
        }

        const nContacts = (m.clients || []).map((c) => ({
          value: c.contact._id,
          label: contactNameResolver(c.contact as any),
        })) as AutoComplete[];
        if (nContacts.length === 1) {
          methods.setValue("client", nContacts[0]);
        }

        setDepartmentId(m.department._id)

        setOptions((old) => ({
          ...old,
          matters: nMatters,
          clients: nContacts,
        }));
      });
      getBankAccount("OFFICE").then((data) => {
        const accs = ((data as BankAccount[]) || []).map((b) => ({
          value: b._id,
          label: `${b.name} (no. ${b.account_number})`,
        })) as AutoComplete[];
        setOptions((old) => ({
          ...old,
          debit_accounts: accs,
        }));
        if (accs.length > 0) {
          methods.setValue("debit_account", accs[0] as AutoComplete);
        }
      });
      getBankAccount("").then((data) => {
        setOptions((old) => ({
          ...old,
          receiving_accounts: ((data as BankAccount[]) || []).map((b) => ({
            value: b._id,
            label: `${b.name} (no. ${b.account_number})`,
          })) as AutoComplete[],
        }));

        const acc =
          ((data as BankAccount[]) || []).find(
            (acc) => acc.type === BankAccountTypes.CLIENT
          ) || null;
        if (acc !== null) {
          methods.setValue("receiving_account", {
            value: acc._id,
            label: `${acc.name} (no. ${acc.account_number})`,
          } as AutoComplete);
        }
      });
      ledgersAPI.invoke({
        page: 0,
        size: 10000,
        matter: matter as string,
        type: BankAccountTypes.OFFICE,
        negative: true,
      });
    }, []);

    useEffect(() => {
      const subscription = methods.watch((value, { name, type, ...rest }) => {
        if (name === "billing_amount" || name === "vat_percentage") {
          setBillingAmountTotal(Number(value.billing_amount) || 0);
          setVatPercentage(Number(value.vat_percentage) || 0);
        }
      });
      return () => subscription.unsubscribe();
    }, [watchForDefaults]);

    useEffect(() => {
      const next = new Decimal(otherFeesSubtotal || 0)
        .plus(new Decimal(firmFeesSubtotal || 0))
        .plus(new Decimal(billingAmountTotal || 0))
        .times(new Decimal(1 + vatPercentage / 100) || 0)
        .plus(new Decimal(disbursementsSubtotal || 0))
        .toNumber();
      setTotalDue(next);
    }, [
      timeEntriesSubtotal,
      otherFeesSubtotal,
      firmFeesSubtotal,
      billingAmountTotal,
      disbursementsSubtotal,
      vatPercentage,
    ]);

    useEffect(() => {
      if (!loaded && allWip) {
        const nextEntries = (wipEntries.data?.pages || []).flatMap(
          (c) => c.result
        );
        setTimeEntries(nextEntries);
        setLoaded(true);
      }
    }, [wipEntries.isFetched]);

    const handleCloseForm = useCallback(() => {
      modal.hide();
    }, [modal]);

    const onSubmit: SubmitHandler<InvoiceForm> = async (data) => {
      try {
        setLoading(true);
        const response = await invoiceCreate({
          draft,
          disbursements: disbursements.map((d) => ({
            _id: d.id as string,
            apply_vat: d.vat || false,
          })),
          narrative: data.narrative,
          billing_amount: Number(data.billing_amount) || 0,
          vat_percentage: Number(data.vat_percentage) || 0,
          less_paid_on_account: Number(data.less_paid_on_account) || 0,
          matter: data.matter?.value || "",
          debit_account: data.debit_account?.value || null,
          receiving_account: data.receiving_account?.value || null,
          solicitor: data.solicitor?.value || null,
          client: data.client?.value || null,
          time_entries: timeEntries.map((d) => d._id),
          due_date: data.due_date as Date,
          invoice_date: (data?.invoice_date as Date) || new Date(),
          payable_by: data.payable_by,
          other_fees: otherFees.map((o) => ({
            description: o.label,
            value: Number(o.value),
          })),
          firm_fees: firmFees.map((o) => ({
            description: o.label,
            value: Number(o.value),
          })),
        });
        modal.resolve(response);
        handleCloseForm();
      } catch (e) {
        enqueueSnackbar(formatError(e), { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    const handleRemoveTimeEntries = (id: string) => {
      if (!id?.length) {
        return;
      }
      const next = timeEntries.filter((t) => t._id !== id);
      setTimeEntries(next);
      setHasTimeEntries(next.length > 0);
    };

    const renderTimeEntries = () => {
      if (!timeEntries.length) {
        if (hasTimeEntries) {
          setHasTimeEntries(false);
        }
        if (timeEntriesSubtotal > 0) {
          setTimeEntriesSubtotal(0);
          setBillingAmountTotal(0);
          methods.setValue("billing_amount", 0);
        }

        if (!wipEntries.isLoading) {
          const nextEntries = (wipEntries.data?.pages || []).flatMap(
            (c) => c.result
          );
          return (
            <Grid item xs={12} mb={1}>
              <Stack
                direction="row"
                spacing={1}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    setTimeEntries(nextEntries);
                  }}
                  disabled={!nextEntries.length}
                >
                  Allocate all WIP
                </Button>
                <Tooltip title="Pulls all billable time entries to this invoice. Any amount less than the billing amount plus legal fees will be written off">
                  <IconButton disableRipple>
                    <Iconify
                      icon="ri:question-line"
                      color={palette.yao.grey[7]}
                    />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Grid>
          );
        }

        return null;
      }
      const total = timeEntries
        .map((e) => new Decimal(e.billable || 0))
        .reduce((p, c) => p.plus(c), new Decimal(0))
        .toNumber();
      if (total !== timeEntriesSubtotal) {
        setTimeEntriesSubtotal(total);
        setBillingAmountTotal(total);
        methods.setValue("billing_amount", total);
      }
      return (
        <Grid item xs={12}>
          <TableContainer
            sx={{
              position: "relative",
              padding: "1px",
              pb: 1,
            }}
          >
            <Table size="small" sx={{ border: 0 }}>
              <TableHead
                sx={{
                  "& .MuiButtonBase-root": {
                    textTransform: "capitalize !important",
                  },
                }}
              >
                <TableCell align="left">Date Posted</TableCell>
                <TableCell align="left">Description</TableCell>
                <TableCell align="left">Units</TableCell>
                <TableCell align="left">Unit Value</TableCell>
                <TableCell align="left">Work Value</TableCell>
                <TableCell
                  align="right"
                  sx={{ width: "80px", pr: 2 }}
                ></TableCell>
              </TableHead>
              <TableBody>
                {timeEntries.map((e) => (
                  <TableRow key={e._id}>
                    <TableCell align="left">{fFullDate(e.date)}</TableCell>
                    <TableCell align="left">{e.description}</TableCell>
                    <TableCell align="left">{e.units}</TableCell>
                    <TableCell align="left">
                      {fCurrencyWithFormat(
                        Math.ceil((e.rate || 0) / 10),
                        currInfo.locale,
                        currInfo.currency,
                        currInfo.symbol
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {fCurrencyWithFormat(
                        e.billable || 0,
                        currInfo.locale,
                        currInfo.currency,
                        currInfo.symbol
                      )}
                    </TableCell>
                    <TableCell align="right" sx={{ width: "80px", pr: 2 }}>
                      <IconButton
                        onClick={() => {
                          handleRemoveTimeEntries(e._id);
                        }}
                      >
                        <Iconify
                          icon="akar-icons:trash-can"
                          color={palette.yao.grey[7]}
                          fontSize={14}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell align="left" colSpan={3} />
                  <TableCell align="left">Subtotal</TableCell>
                  <TableCell align="left">
                    {fCurrencyWithFormat(
                      total,
                      currInfo.locale,
                      currInfo.currency,
                      currInfo.symbol
                    )}
                  </TableCell>
                  <TableCell align="left">&nbsp;</TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      );
    };

    const handleAddDisbursement = () => {
      setDisbursements((old) => [
        ...old,
        { id: null, label: "", value: "", vat: false },
      ]);
    };

    const handleDisbursementTotal = (rows: AutoComplete[]) => {
      const total = rows
        .map((d) => new Decimal(Number(d.value) || 0))
        .reduce((p, c) => p.plus(c), new Decimal(0))
        .toNumber();
      setDisbursementsSubtotal(total);
      setDisbursementsIds(rows.map((r) => r.id as string));
    };

    const getValueWithVat = (
      subtotal: number = 0,
      vat: number = 0,
      hasVat: boolean = false
    ) => {
      if (!hasVat) {
        return new Decimal(subtotal).toNumber();
      }
      const nextVat =
        (subtotal < 0 && vat >= 0) || (subtotal > 0 && vat < 0)
          ? vat * -1
          : vat;
      return new Decimal(nextVat).plus(new Decimal(subtotal)).toNumber();
    };

    const handleChangeDisbursement = (index: number, item: AutoComplete) => {
      const disbursementsUpdated: AutoComplete[] = [...disbursements];
      const hasVat =
        !new Decimal(item.ledger?.vat || 0).equals(0) &&
        new Decimal(item.ledger?.subtotal || 0).equals(
          new Decimal(item.ledger?.value || 0)
        );

      let subtotal = item.ledger?.subtotal || 0;
      if (subtotal < 0) {
        subtotal = Math.abs(subtotal);
      } else {
        subtotal = subtotal * -1;
      }
      const value = getValueWithVat(subtotal, item.ledger?.vat || 0, hasVat);

      // @ts-ignore
      disbursementsUpdated[index] = {
        ...item,
        vat: hasVat,
        value: value.toString(),
      };
      setDisbursements(disbursementsUpdated);
      handleDisbursementTotal(disbursementsUpdated);
    };

    const handleDisbursementVat = (
      e: ChangeEvent<HTMLInputElement>,
      index: number
    ) => {
      let disbursementsUpdated: AutoComplete[] = [...disbursements];

      const hasVatNext = e.target.checked;

      const item = { ...disbursementsUpdated[index] };
      item.vat = hasVatNext;

      let subtotal = item.ledger?.subtotal || 0;
      if (subtotal < 0) {
        subtotal = Math.abs(subtotal);
      } else {
        subtotal = subtotal * -1;
      }

      let value = 0;
      const originalHasVat =
        !new Decimal(item.ledger?.vat || 0).equals(0) &&
        new Decimal(item.ledger?.subtotal || 0).equals(
          new Decimal(item.ledger?.value || 0)
        );
      if (originalHasVat && hasVatNext) {
        value = getValueWithVat(subtotal, item.ledger?.vat || 0, hasVatNext);
      } else if (hasVatNext) {
        const amount = new Decimal(subtotal);
        const newVat = new Decimal(
          Number(methods.getValues("vat_percentage")) || 0
        )
          .dividedBy(100)
          .times(amount)
          .toNumber();
        value = getValueWithVat(subtotal, newVat, hasVatNext);
      } else {
        value = getValueWithVat(subtotal, 0, false);
      }

      item.value = value.toString();
      disbursementsUpdated[index] = { ...item };

      setDisbursements(disbursementsUpdated);
      handleDisbursementTotal(disbursementsUpdated);
    };

    const handleremoveDisbursement = (index: number) => {
      let disbursementsUpdated: AutoComplete[] = [...disbursements];
      disbursementsUpdated.splice(index, 1);
      setDisbursements(disbursementsUpdated);
      handleDisbursementTotal(disbursementsUpdated);
    };

    const handleAddFee = () => {
      setOtherFees((old) => [...old, { id: null, label: "", value: "" }]);
    };

    const handleAddFirmFee = () => {
      setFirmFees((old) => [...old, { id: null, label: "", value: "" }]);
    };

    const handleChangeFee = (
      index: number,
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      const otherFeesUpdated: AutoComplete[] = [...otherFees];
      // @ts-ignore
      otherFeesUpdated[index][event.target.name] = event.target.value || "";
      setOtherFees(otherFeesUpdated);
      handleFeeTotal(otherFeesUpdated);
    };

    const handleChangeFirmFee = (
      index: number,
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      const firmFeesUpdated: AutoComplete[] = [...firmFees];
      // @ts-ignore
      firmFeesUpdated[index][event.target.name] = event.target.value || "";
      setFirmFees(firmFeesUpdated);
      handleFirmFeeTotal(firmFeesUpdated);
    };

    const handleRemoveFee = (index: number) => {
      let otherFeesUpdated: AutoComplete[] = [...otherFees];
      otherFeesUpdated.splice(index, 1);
      setOtherFees(otherFeesUpdated);
      handleFeeTotal(otherFeesUpdated);
    };

    const handleRemoveFirmFee = (index: number) => {
      let firmFeesUpdated: AutoComplete[] = [...firmFees];
      firmFeesUpdated.splice(index, 1);
      setFirmFees(firmFeesUpdated);
      handleFirmFeeTotal(firmFeesUpdated);
    };

    const handleFeeTotal = (rows: AutoComplete[]) => {
      const total = rows
        .map((d) => new Decimal(Number(d.value) || 0))
        .reduce((p, c) => p.plus(c), new Decimal(0))
        .toNumber();
      setOtherFeesSubtotal(total);
    };

    const handleFirmFeeTotal = (rows: AutoComplete[]) => {
      const total = rows
        .map((d) => new Decimal(Number(d.value) || 0))
        .reduce((p, c) => p.plus(c), new Decimal(0))
        .toNumber();
      setFirmFeesSubtotal(total);
    };


    const narrativeRows = useMemo(() => {
      return (narrativesQuery.data?.pages || [])
          .flatMap((page) => page.rows || [])
          .map((narrative) => ({
              ...narrative,
          }))
          .map((narrativeItem: Narrative) => {
              const value = JSON.stringify({
                  narrative_id: narrativeItem._id,
                  narrative_text: narrativeItem.text,
                  narrative_type: narrativeItem.type
              });
              return {
                  label: _.capitalize(narrativeItem.text),
                  value,
                  changeVal: value,
              };
          })
          .reduce((acc, curr) => {
              if (!acc.some((item: any) => item.label === curr.label)) {
                  acc.push(curr);
              }
              return acc;
          }, [] as YAOFieldAutocompleteOption[]);
    }, [narrativesQuery.data]);

    const updateFilter = (key: keyof NarrativeQueryRequest, value: any) => {
      setFilters((prev) => ({ ...prev, [key]: value }));
      refetchQuery();
    };

    const handleSearchFilter = (searchValue: string) =>
      updateFilter("search", searchValue?.trim());

    const addNarrative = async () => {
        const isAddedNarrative = (items: {label: string}[]) => items
            .map(item => item?.label)
            .some((item: string) => item.toLocaleLowerCase() === inputNarrativeValue.trim().toLocaleLowerCase())

        if (!inputNarrativeValue?.length) return
        // @ts-ignore
        if(isAddedNarrative([...narrativeRows])) return

        await createNarrative({
          type: NarrativeType.INVOICE,
          text: inputNarrativeValue,
          department: departmentId,
      })
      enqueueSnackbar(`Narrative ${inputNarrativeValue} created successfully`);
      refetchQuery()
    }

    const renderEntriesWIP = useMemo(() => {
      if (!timeEntries.length) {
        return null;
      }

      const billed = new Decimal(billingAmountTotal)
        .plus(new Decimal(otherFeesSubtotal))
        .plus(new Decimal(firmFeesSubtotal))
        .toNumber();
      const written_off = new Decimal(timeEntriesSubtotal)
        .minus(new Decimal(billed))
        .toNumber();

      return (
        <Stack direction="column" spacing={0}>
          {written_off > 0 ? (
            <Typography fontSize={12} color="info.main">
              {fCurrencyWithFormat(
                billed,
                currInfo.locale,
                currInfo.currency,
                currInfo.symbol
              )}{" "}
              of time entries will be billed and{" "}
              {fCurrencyWithFormat(
                written_off,
                currInfo.locale,
                currInfo.currency,
                currInfo.symbol
              )}{" "}
              written off
            </Typography>
          ) : (
            <Typography fontSize={12} color="info.main">
              {fCurrencyWithFormat(
                billed,
                currInfo.locale,
                currInfo.currency,
                currInfo.symbol
              )}{" "}
              of time entries will be billed
            </Typography>
          )}
        </Stack>
      );
    }, [
      timeEntries.length,
      billingAmountTotal,
      otherFeesSubtotal,
      timeEntriesSubtotal,
      firmFeesSubtotal,
    ]);

    return (
      <Dialog
        maxWidth="md"
        open={modal.visible}
        onClose={handleCloseForm}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
      >
        <Stack
          width={680}
          height="100%"
          padding={2}
          role="presentation"
          direction="column"
          spacing={1}
          justifyContent="flex-start"
        >
          <FormProvider
            methods={methods}
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="h2"
                textAlign="center"
                flexGrow={1}
                flexShrink={1}
              >
                Add Invoice
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={draft}
                    onChange={(e) => setDraft(e.target.checked)}
                  />
                }
                sx={{ marginRight: 0, fontSize: "13px" }}
                label="Draft Only"
              />
            </Stack>
            <Grid container rowSpacing={1.5} columnSpacing={2.5}>
              <Grid item xs={12} md={6}>
                <YaoFormFieldLabel name="client" label="Client" />
                <YAOFieldAutocomplete
                  name="client"
                  label=""
                  placeholder="Select client"
                  disabled={loading}
                  options={options.clients}
                  rules={{
                    required: "Client is required",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <YaoFormFieldLabel name="solicitor" label="Choose solicitor" />
                <YAOFieldAutocomplete
                  name="solicitor"
                  label=""
                  placeholder="Choose solicitor"
                  disabled={loading}
                  options={options.solicitors}
                  rules={{
                    required: "Solicitor is required",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <YaoFormFieldLabel name="debit_account" label="Debit Account" />
                <YAOFieldAutocomplete
                  name="debit_account"
                  label=""
                  placeholder="Select debit account"
                  disabled={loading}
                  options={options.debit_accounts}
                  rules={{
                    required: "Debit account is required",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <YaoFormFieldLabel
                  name="receiving_account"
                  label="Receiving Account"
                />
                <YAOFieldAutocomplete
                  name="receiving_account"
                  label=""
                  placeholder="Select receiving account"
                  disabled={loading}
                  options={options.receiving_accounts}
                  rules={{
                    required: "Receiving account is required",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <YaoFormFieldLabel
                  name="vat_percentage"
                  label={lawFirmContext.getTaxName()}
                />
                <YAOFieldCurrency
                  name="vat_percentage"
                  label=""
                  type="text"
                  placeholder="0"
                  disabled={loading}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <YaoFormFieldLabel name="invoice_date" label="Invoice date" />
                <YAOFieldDatePicker
                  name="invoice_date"
                  datePickerProps={{
                    views: ["year", "month", "day"],
                    disabled: loading,
                  }}
                  rules={{
                    required: "Invoice date is required",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <YaoFormFieldLabel name="due_date" label="Due date" />
                <YAOFieldDatePicker
                  name="due_date"
                  datePickerProps={{
                    views: ["year", "month", "day"],
                    disabled: loading,
                  }}
                  rules={{
                    required: "Due date is required",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <YaoFormFieldLabel
                  name="billing_amount"
                  label="Billing amount"
                />
                <YAOFieldCurrency
                  name="billing_amount"
                  label=""
                  type="text"
                  placeholder="Enter amount"
                  disabled={loading}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {lawFirmContext.getCurrencySymbol()}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}></Grid>
              <Grid item xs={12} md={4}>
                <YaoFormFieldLabel
                  name="less_paid_on_account"
                  label="Less paid on account"
                />
                <YAOFieldCurrency
                  name="less_paid_on_account"
                  label=""
                  type="text"
                  placeholder="Enter amount"
                  disabled={loading}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {lawFirmContext.getCurrencySymbol()}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <YaoFormFieldLabel name="payable_by" label="Payable By" />
                <YAOFieldText
                  name="payable_by"
                  label=""
                  type="text"
                  placeholder="Enter name"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                  <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent="space-between"
                  >
                      <Grid item>
                          <YaoFormFieldLabel name="narrative" label="Narrative" />
                      </Grid>
                      <Grid item>
                          <Tooltip title="Remember for future use" >
                              <LibraryAdd color="info" fontSize="medium" onClick={addNarrative}/>
                          </Tooltip>
                      </Grid>
                  </Grid>
                  <Autocomplete
                      id="narrative.input"
                      label="Narrative"
                      loading={narrativesQuery.isFetchingNextPage}
                      options={narrativeRows}
                      disableClearable
                      freeSolo
                      handleHomeEndKeys
                      selectOnFocus
                      renderInput={(params) => (
                          <YAOFieldText
                              {...params}
                              // fullWidth
                              name="narrative"
                              placeholder={`Default: Work in connection with ${
                                  methods.getValues("matter.label") || "..."
                              }`}
                              InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                      <>
                                          {narrativesQuery.isFetchingNextPage ? <CircularProgress color="inherit" size={20} /> : null}
                                          {params.InputProps.endAdornment}
                                      </>
                                  ),
                              }}
                          />
                      )}
                      inputValue={inputNarrativeValue}
                      onInputChange={(e: SyntheticEvent, value: string) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setInputNarrativeValue(value);

                          handleSearchFilter(value);
                          refetchQuery()
                      }}
                      ListboxProps={{
                          // @ts-ignore
                          onScroll: (e: SyntheticEvent, value: any) => {
                              const listboxNode = e.currentTarget;
                              if (
                                  listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 10
                                  && narrativesQuery.hasNextPage && !narrativesQuery.isFetchingNextPage
                              ) {
                                  narrativesQuery.fetchNextPage();
                              }
                          }
                      }}
                      onChange={(e: SyntheticEvent, newValue: any) => {
                          e.preventDefault();
                          e.stopPropagation();
                          let name = "";
                          if (typeof newValue === "string") {
                              name = newValue;
                          } else if (newValue && newValue.inputValue) {
                              name = newValue.inputValue;
                          } else {
                              name = newValue.title ?? newValue.label;
                          }
                          setInputNarrativeValue(name);
                      }}
                  />
              </Grid>
              <Grid item xs={12} ref={ref} sx={{ height: 0 }} />

              {renderTimeEntries()}

              <Grid item xs={12}>
                {otherFees.map((element, index) => (
                  <div key={index}>
                    <YaoFormFieldLabel
                      name="otherFees"
                      label={
                        otherFees.length === 1
                          ? "Legal fees"
                          : `${index + 1}. Legal fees`
                      }
                    />
                    <Grid container>
                      <Grid item xs={7} sx={{ paddingRight: "8px" }}>
                        <TextField
                          type="text"
                          name="label"
                          label="Add description"
                          value={element.label}
                          onChange={(e) => handleChangeFee(index, e)}
                          variant="outlined"
                          margin="none"
                          className="yao-input-override"
                          fullWidth
                          error={!element.label?.length}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          type="text"
                          name="value"
                          label="Enter amount"
                          value={element.value}
                          error={!element.value?.length}
                          onChange={(e) => handleChangeFee(index, e)}
                          variant="outlined"
                          margin="none"
                          className="yao-input-override"
                          fullWidth
                          InputProps={{
                            inputComponent: NumberFormatCustom as any,
                            endAdornment: (
                              <InputAdornment position="end">
                                {lawFirmContext.getCurrencySymbol()}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton onClick={() => handleRemoveFee(index)}>
                          <Iconify
                            icon="akar-icons:trash-can"
                            color={palette.yao.grey[7]}
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </div>
                ))}
              </Grid>
              <Grid item xs={12} mb={1}>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Button variant="outlined" onClick={handleAddFee}>
                    Allocate Legal fees
                  </Button>
                  <Tooltip title="Lawyer fees that get allocated towards WIP, if selected any">
                    <IconButton disableRipple>
                      <Iconify
                        icon="ri:question-line"
                        color={palette.yao.grey[7]}
                      />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                {firmFees.map((element, index) => (
                  <div key={index}>
                    <YaoFormFieldLabel
                      name="firmFees"
                      label={
                        firmFees.length === 1
                          ? "Firm fees"
                          : `${index + 1}. Firm fees`
                      }
                    />
                    <Grid container>
                      <Grid item xs={7} sx={{ paddingRight: "8px" }}>
                        <TextField
                          type="text"
                          name="label"
                          label="Add description"
                          value={element.label}
                          onChange={(e) => handleChangeFirmFee(index, e)}
                          variant="outlined"
                          margin="none"
                          className="yao-input-override"
                          fullWidth
                          error={!element.label?.length}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          type="text"
                          name="value"
                          label="Enter amount"
                          value={element.value}
                          error={!element.value?.length}
                          onChange={(e) => handleChangeFirmFee(index, e)}
                          variant="outlined"
                          margin="none"
                          className="yao-input-override"
                          fullWidth
                          InputProps={{
                            inputComponent: NumberFormatCustom as any,
                            endAdornment: (
                              <InputAdornment position="end">
                                {lawFirmContext.getCurrencySymbol()}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton onClick={() => handleRemoveFirmFee(index)}>
                          <Iconify
                            icon="akar-icons:trash-can"
                            color={palette.yao.grey[7]}
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </div>
                ))}
              </Grid>
              <Grid item xs={12} mb={1}>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Button variant="outlined" onClick={handleAddFirmFee}>
                    Allocate Firm fees
                  </Button>
                  <Tooltip title="Law firm or transaction fees that do not get accounted on lawyer's WIP">
                    <IconButton disableRipple>
                      <Iconify
                        icon="ri:question-line"
                        color={palette.yao.grey[7]}
                      />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                {disbursements.map((element, index) => (
                  <div key={index}>
                    <Grid container>
                      <Grid item xs={6} sx={{ paddingRight: "8px" }}>
                        <YaoFormFieldLabel
                          name="disbursements"
                          label={
                            disbursements.length === 1
                              ? "Disbursement"
                              : `${index + 1}. Disbursement`
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <YaoFormFieldLabel
                          name="disbursements"
                          label={`Check to add ${lawFirmContext.getTaxName()}`}
                        />
                      </Grid>
                      <Grid item xs={6} sx={{ paddingRight: "8px" }}>
                        <Autocomplete
                          disablePortal
                          id={`select-${index}`}
                          options={(ledgersAPI.data || []).map((d) => ({
                            label: `${d.reference || ""} ${fCurrencyWithFormat(
                              d.value < 0 ? Math.abs(d.value) : d.value * -1,
                              currInfo.locale,
                              currInfo.currency,
                              currInfo.symbol
                            )}`,
                            id: d._id,
                            value: d.value,
                            ledger: d,
                          }))}
                          getOptionLabel={(option) => option.label}
                          renderInput={(params) => (
                            <TextField {...params} label="" />
                          )}
                          disableClearable
                          value={element}
                          onChange={(_: any, newValue: any | null) => {
                            if (newValue !== null) {
                              handleChangeDisbursement(
                                index,
                                newValue as AutoComplete
                              );
                            }
                          }}
                          getOptionDisabled={(option) =>
                            disbursementsIds.indexOf(option.id) !== -1
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Checkbox
                          checked={element.vat}
                          onChange={(e) => handleDisbursementVat(e, index)}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          type="text"
                          name="value"
                          label=""
                          value={element.value}
                          disabled
                          variant="outlined"
                          margin="none"
                          className="yao-input-override"
                          fullWidth
                          InputProps={{
                            inputComponent: NumberFormatCustom as any,
                            endAdornment: (
                              <InputAdornment position="end">
                                {lawFirmContext.getCurrencySymbol()}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          onClick={() => handleremoveDisbursement(index)}
                        >
                          <Iconify
                            icon="akar-icons:trash-can"
                            color={palette.yao.grey[7]}
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </div>
                ))}
              </Grid>
              <Grid item xs={12} mb={1}>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Button
                    variant="outlined"
                    onClick={handleAddDisbursement}
                    disabled={disbursements.length === ledgersAPI.data?.length}
                  >
                    Allocate Disbursement
                  </Button>
                  <Tooltip title="Select any disbursement or anticipated disbursement to issue with the invoice">
                    <IconButton disableRipple>
                      <Iconify
                        icon="ri:question-line"
                        color={palette.yao.grey[7]}
                      />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack direction="column" spacing={1} width="100%">
                  <Stack
                    direction="row"
                    spacing={1}
                    borderTop="1px solid #EBF2F3"
                    padding={1}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {renderEntriesWIP}
                    <Box flexGrow={1} flexShrink={1} />
                    <Box>
                      <Typography
                        textAlign="right"
                        fontWeight={400}
                        fontSize={13}
                        sx={{
                          color: palette.yao.grey[7],
                        }}
                      >
                        TOTAL DUE
                      </Typography>
                    </Box>
                    <Box>
                      <Typography fontWeight={600} fontSize={13}>
                        {fCurrencyWithFormat(
                          totalDue,
                          currInfo.locale,
                          currInfo.currency,
                          currInfo.symbol
                        )}
                      </Typography>
                    </Box>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </FormProvider>
          <Stack spacing={1} direction="row" justifyContent="flex-end">
            <Button
              disabled={loading}
              color="secondary"
              onClick={handleCloseForm}
            >
              Cancel
            </Button>
            <Box sx={{ m: 1, position: "relative" }}>
              <Button
                disabled={loading}
                variant="contained"
                sx={{ minWidth: 100 }}
                type="submit"
                onClick={methods.handleSubmit(onSubmit)}
              >
                Add
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Box>
          </Stack>
        </Stack>
      </Dialog>
    );
  }
);
